<script>
  import { title, breadcrumbPaths } from '../stores/core-store';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';

  title.set('App Settings');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'App Settings',
      location: '/app-settings',
    },
  ]);
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <h2 class="gigxr-list-header gigxr-list-header--first app-settings-header">Downloaded Resources</h2>
    <div class="app-settings-content-view">No additional downloads.</div>

    <h2 class="gigxr-list-header app-settings-header">Content Preferences</h2>
    <div class="app-settings-content-view">Default content preferences.</div>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .app-settings-header {
    margin-bottom: 1em;
  }
  .app-settings-content-view {
    margin-bottom: 30px;
    padding-top: 10px;
    text-align: center;
    color: var(--gigxr-theme-primary-1a);
  }
</style>
