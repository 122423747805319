import { Logger } from './logs';

function splitTextByLines(text = '') {
  const lineBreakMarker = '$LINE_BREAK_MARKER$';

  text = text.replace(/\r\n/gi, lineBreakMarker);
  text = text.replace(/\r/gi, lineBreakMarker);
  text = text.replace(/\n/gi, lineBreakMarker);

  return text.split(lineBreakMarker);
}

function splitTextByUppercaseLetter(text = '') {
  return text.split(/(?=[A-Z])/).join(' ');
}

export { splitTextByLines, splitTextByUppercaseLetter };
