<script>
  import { onMount, onDestroy } from 'svelte';
  import { title, breadcrumbPaths } from '../stores/core-store';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';
  import {
    subscribeToUnityMessage,
    sendUnityGetContentPreferencesMessage,
    sendUnitySetContentPreferencesMessage,
    sendUnityGetDownloadsMessage,
    getClientAppId,
  } from '../util/unity';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import Select, { Option } from '@smui/select';
  import ListPageNoResultsMessage from '../components/ListPageNoResultsMessage.svelte';
  import { bytesToGigabytes, gigabytesToBytes, humanFileSize } from '../util/util';
  import LoadingView from '../components/LoadingView.svelte';
  import { startAllResourceDownloads } from '../util/download-manager';
  import BottomModal, { showBottomModal } from '../components/BottomModal.svelte';
  import AppSettingsResourceCard from '../components/AppSettingsResourceCard.svelte';
  import { fetchClientAppManifest } from '../util/api/client-apps';
  import { fetchSessionResourceAliases } from '../util/api/sessions';

  title.set('App Settings');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'App Settings',
      location: '/app-settings',
    },
  ]);

  let contentPreferences;
  let totalStorageUsed;
  let unsubscribeFunctions = [];

  let resources;
  let clientAppManifest;
  let resourceAliases = {};

  $: loading = !contentPreferences || !resources;

  onMount(async () => {
    [clientAppManifest, resourceAliases] = await Promise.all([
      fetchClientAppManifest(getClientAppId()),
      fetchSessionResourceAliases(),
    ]);

    unsubscribeFunctions = [
      subscribeToUnityMessage(UnityAction.GET_DOWNLOADS_RESPONSE, (payload) => {
        resources = payload;
      }),
      subscribeToUnityMessage(UnityAction.CONTENT_PREFERENCES_RESPONSE, (payload) => {
        contentPreferences = payload;
      }),
    ];

    pollForDownloads();
    sendUnityGetContentPreferencesMessage();
  });

  let pollForDownloadsTimeout;
  function pollForDownloads() {
    sendUnityGetDownloadsMessage();

    pollForDownloadsTimeout = setTimeout(() => {
      pollForDownloads();
    }, 1000);
  }

  // This reactive block is to calculate total storage used.
  $: if (resources) {
    let bytes = 0;
    resources.forEach((resource) => {
      if (resource.length > 0) {
        bytes += resource.length / resource.progress;
      }
    });
    totalStorageUsed = bytes;
  }

  // This reactive block is to send content preference updates on change.
  $: if (contentPreferences) {
    const contentPreferencesDto = {
      ...contentPreferences,
      // Coerece to number. There does not appear to be a way to bind to a number type from a select field at this time.
      maximumContentStorage: Number(contentPreferences.maximumContentStorage),
    };

    sendUnitySetContentPreferencesMessage(contentPreferencesDto);

    if (contentPreferencesDto.allowAutomaticBackgroundDownloads) {
      // This will trigger automatically later, but this is so downloads will start right away.
      startAllResourceDownloads();
    }
  }

  let maximumContentMessage = '';
  function maximumContentStorageChangeHandler(event) {
    const element = document.getElementById('maximum-content-storage');

    if (bytesToGigabytes(totalStorageUsed) > Number(element.value)) {
      const storageUsingMessage = humanFileSize(totalStorageUsed, true);
      const storageToDeleteMessage = humanFileSize(totalStorageUsed - gigabytesToBytes(element.value), true);
      const storageToSetMessage = humanFileSize(gigabytesToBytes(element.value), true);
      maximumContentMessage = `You are currently using ${storageUsingMessage} of storage. You must delete ${storageToDeleteMessage} of storage before switching to ${storageToSetMessage} of maximum content storage.`;

      element.value = contentPreferences.maximumContentStorage;

      showBottomModal();
    }
  }

  onDestroy(() => {
    clearTimeout(pollForDownloadsTimeout);
    unsubscribeFunctions.forEach((fn) => fn());
  });
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <h2 class="gigxr-list-header gigxr-list-header--first app-settings-header">Downloaded Resources</h2>

    {#if loading}
      <LoadingView />
    {:else}
      {#each resources as resource}
        <AppSettingsResourceCard
          resourceId={resource.resourceId}
          name={clientAppManifest?.resourcesById[resource.resourceId]?.name}
          size={resource.length}
          progress={resource.progress}
          displayNames={resourceAliases[resource.resourceId] ?? []}
        />
      {/each}
      {#if resources.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no resources to display.</h3>
        </ListPageNoResultsMessage>
      {/if}
    {/if}

    <h2 class="gigxr-list-header app-settings-header">Content Preferences</h2>

    {#if loading}
      <LoadingView />
    {:else}
      <div class="app-setting-preferences">
        <div class="app-setting-preferences__row">
          <div class="app-setting-preferences__description">
            <label for="allow-automatic-background-downloads">
              <div class="app-setting-preferences__description-label">Allow automatic background downloads</div>
            </label>
          </div>
          <div class="app-setting-preferences__control">
            <FormField inputId="allow-automatic-background-downloads-field">
              <Checkbox bind:checked={contentPreferences.allowAutomaticBackgroundDownloads} />
            </FormField>
          </div>
        </div>
        <div class="app-setting-preferences__row">
          <div class="app-setting-preferences__description">
            <label for="maximum-content-storage">
              <div class="app-setting-preferences__description-label">Maximum content storage</div>
              <div class="app-setting-preferences__description-helper-text">
                Currently using
                {#if resources && resources.length > 0}
                  {humanFileSize(totalStorageUsed, true)}
                {:else if resources && resources.length === 0}no storage{:else}–{/if}
              </div>
            </label>
          </div>
          <div class="app-setting-preferences__control">
            <Select
              inputId="maximum-content-storage-field"
              class="gigxr-input gigxr-outlined gigxr-select__no-label"
              variant="outlined"
              label=""
              on:change={maximumContentStorageChangeHandler}
              bind:value={contentPreferences.maximumContentStorage}
            >
              <Option value="2">2 GB</Option>
              <Option value="4">4 GB</Option>
              <Option value="8">8 GB</Option>
              <Option value="16">16 GB</Option>
              <Option value="32">32 GB</Option>
            </Select>
          </div>
        </div>
      </div>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<BottomModal buttonText="OK">
  <p>{maximumContentMessage}</p>
</BottomModal>

<style>
  .app-settings-header {
    margin-bottom: 1em;
  }

  .app-setting-preferences__row {
    display: flex;
    margin-bottom: 1em;
  }

  .app-setting-preferences__description {
    flex: 2;
  }

  .app-setting-preferences__description-label {
    font-weight: 700;
  }

  .app-setting-preferences__control {
    flex: 1;
    text-align: right;
    padding-left: 10px;
  }
</style>
