import { defaultHeaders, authenticatedGet } from '../api';
import { GmsError } from '../errors';
import { GENERIC_ERROR_MESSAGE } from '../constants';

/**
 * ClientAppView
 * @typedef {Object} ClientAppView
 * @property {string} clientAppId
 * @property {string} clientAppName
 */

/**
 * @return {Promise<[ClientAppView]>}
 */
async function fetchClientApps() {
  const response = await authenticatedGet('/client-apps');
  if (!response.ok) {
    throw new GmsError(`Error fetching client applications! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

/**
 * @return {Promise<[ClientAppView]>}
 */
async function fetchLicensedClientApps() {
  const response = await authenticatedGet('/client-apps/licensed');
  if (!response.ok) {
    throw new GmsError(`Error fetching client applications! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchClientAppVersions(clientAppId) {
  const response = await authenticatedGet(`/client-apps/${clientAppId}/versions`, {...defaultHeaders, 'api-version': '1.1'});
  if (!response.ok) {
    throw new GmsError(`Error fetching application versions! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function fetchPathologies(clientAppId) {
  const response = await authenticatedGet(`/client-apps/${clientAppId}/pathologies`);
  if (!response.ok) {
    throw new GmsError(`Error fetching pathologies! ${GENERIC_ERROR_MESSAGE}`);
  }
  let jsonString = await response.text();
  let json = JSON.parse(jsonString);

  // TODO: remove all hardcoding on HoloScenarios and its AppId 
  if (json.clientAppId === '5516eb34-611a-4eac-b2d6-834a8c1d4509') {
    jsonString = jsonString.replace("BloodPressureHigh", "BloodPressureSYS").replace("BloodPressureLow", "BloodPressureDIA");
    json = JSON.parse(jsonString);
  }

  if (!json || !json.data || !json.data.pathologyJson || !json.data.pathologyJson.PathologyList) {
    throw new GmsError(`Error parsing pathologies! ${GENERIC_ERROR_MESSAGE}`);
  }

  return json.data.pathologyJson.PathologyList;
}

async function fetchClientAppManifest(clientAppId) {
  const response = await authenticatedGet(`/client-apps/${clientAppId}/manifest`);
  if (!response.ok) {
    // we're going to return an empty array of resources if none.
    return {"resources": []};
  }

  const json = await response.json();

  if (!json || !json.data || !json.data.manifest) {
    throw new GmsError(`Error parsing manifest! ${GENERIC_ERROR_MESSAGE}`);
  }

  return json.data.manifest;
}

async function fetchFirebaseDynamicLink(clientAppId, path) {
  const response = await authenticatedGet(`/client-apps/${clientAppId}/firebase-dynamic-link?path=${path}`);
  if (!response.ok) {
    throw new GmsError(`Error fetching dynamic link! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

export { fetchClientApps, fetchLicensedClientApps, fetchClientAppVersions, fetchPathologies, fetchClientAppManifest, fetchFirebaseDynamicLink };
