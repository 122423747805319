class SortBySet {
  constructor({ description, defaultValue, sortOptions }) {
    this.description = description;
    this.defaultValue = defaultValue;
    this.sortOptions = sortOptions;
    this.selectedValue = defaultValue;
  }

  /**
   * @param {[*]} array
   * @param {string} entity
   * @returns {[*]} A reference to the same array, sorted.
   */
  sort(array, entity) {
    if (entity) {
      if (this.description === 'Sort by') {
        sessionStorage.setItem(`sort${entity}ByDate`, this.selectedValue);
      }
    }
    return array.sort(this.sortOptions.get(this.selectedValue));
  }
}

class FilterBySet {
  constructor({ description, defaultValue, filterOptions, filterCallback }) {
    this.description = description;
    this.defaultValue = defaultValue;
    this.filterOptions = filterOptions;
    this.selectedValue = defaultValue;
    this._filterCallback = null;

    if (typeof filterCallback === 'function') {
      this._filterCallback = filterCallback;
    }

    if (this.constructor === FilterBySet) {
      throw new Error('FilterBySet is abstract and cannot be instantiated directly!');
    }
  }

  /**
   * @param {[*]} array
   * @param {string} entity
   * @returns {[*]}
   */
  filter(array, entity) {
    if (entity) {
      if (this.description === 'Filter by Lesson Date') {
        sessionStorage.setItem(`filter${entity}ByLessonDate`, this.selectedValue);
      } else if (this.description === 'Filter by Class') {
        sessionStorage.setItem(`filter${entity}ByClass`, this.selectedValue);
      } else if (this.description === 'Filter by App') {
        sessionStorage.setItem(`filter${entity}ByClientApp`, this.selectedValue);
      } else if (this.description === 'Filter by Version') {
        sessionStorage.setItem(`filter${entity}ByVersion`, this.selectedValue);
      } else if (this.description === 'Archived Sessions' || this.description === 'Archived Session Plans') {
        sessionStorage.setItem(`filter${entity}ByArchived`, this.selectedValue);
      }
    }
    if (typeof this._filterCallback === 'function') {
      return this._filterCallback(array, this.selectedValue);
    } else {
      const filterCallback = this.filterOptions[this.selectedValue];

      if (filterCallback) {
        return array.filter(this.filterOptions[this.selectedValue]);
      }
      console.warn('Could not find valid filterCallback!');

      return array;
    }
  }
}

class FilterByRadioSet extends FilterBySet {
  constructor({ description, defaultValue, filterOptions, filterCallback }) {
    super({ description, defaultValue, filterOptions, filterCallback });
  }
}

class FilterBySelectSet extends FilterBySet {
  constructor({ description, defaultValue, filterOptions, filterCallback, label }) {
    super({ description, defaultValue, filterOptions, filterCallback });
    this.label = label;
  }
}

class FilterByCheckboxSet extends FilterBySet {
  constructor({ description, defaultValue, filterOptions, filterCallback }) {
    super({ description, defaultValue, filterOptions, filterCallback });
  }
}

export { SortBySet, FilterBySet, FilterByRadioSet, FilterBySelectSet, FilterByCheckboxSet };
