<script>
  import { onMount } from 'svelte';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';
  import { isAuthenticated } from '../stores/core-store';
  import { authenticatedGet, defaultHeaders } from '../util/api';
  import { Logger } from '../util/logs';
  import { getQueryParameter } from '../util/util';
  import { BASE_API_URL, DEVELOPMENT } from '../config';
  import { generateQrCodeImageData } from '../util/qr-codes';

  let imageSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  async function fetchAppSecret(clientAppId) {
    var response = await authenticatedGet(
      clientAppId ? `/client-apps/secret?clientAppId=${clientAppId}` : `/client-apps/secret`,
      { ...defaultHeaders, 'api-version': '1.1' },
    );
    if(response.status == 200) {
        return response.json();
    }
  }

  onMount(async () => {
    let clientAppId = getQueryParameter('clientAppId');
    let qrData = {
      name: window.location.host,
      // TODO: this assumes the API server is running on the same host as the web server, which is not always the case on new infra
      // Additionally it does not work in development mode, where the API server is running on a different port
      URL: `${window.location.protocol}//${window.location.host}`,
      GmsVersion: '1.1',
      isQaEnvironment: DEVELOPMENT
    };
    var clientAppData = await fetchAppSecret(clientAppId);
    qrData = {...qrData, ...clientAppData?.data };
    console.log(qrData);
    imageSrc = await generateQrCodeImageData(JSON.stringify(qrData));
  });
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <div class="qr_wrapper"><img id="qrcode" alt="Switch server QR code" src={imageSrc} /></div>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .qr_wrapper {
    display: flex;
    justify-content: center;
  }
  #qrcode {
    width: 50%;
  }
</style>
