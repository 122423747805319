<script>
  import TwoColumnSection from '../TwoColumnSection.svelte';
  import { roundMinutesToNearestFive, utcStringToLocalDate } from '../../util/dates';
  import DateAccountComponent from '../DateAccountComponent.svelte';
  import ClientAppLogo from '../client-apps/ClientAppLogo.svelte';
  import ContentRow from '../ContentRow.svelte';
  import Textfield from '@smui/textfield';
  import FilledTextArea from '../../components/FilledTextArea.svelte';
  import Select, { Option } from '@smui/select';
  import { getAccountRole } from '../../util/account';
  import SessionPermissionSelect from './SessionPermissionSelect.svelte';
  import SessionScenesPanel from './SessionScenesPanel.svelte';
  import GigXrDatepicker from '../GigXrDatepicker.svelte';
  import GigXrTimepicker from '../GigXrTimepicker.svelte';
  import SessionContentManager from './SessionContentManager.svelte';
  import { isHoloScenarios, isLegacyHoloChem, isLegacyHoloHuman, isLegacyHoloPatient, isUnity } from '../../util/unity';
  import PlatformStagePanel from './PlatformStagePanel.svelte';
  import PlatformV3ContentPanel from './PlatformV3ContentPanel.svelte';
  import PlatformSessionContentManager from './HoloHumanSessionContentManager.svelte';
  import { AccountRole } from '../../util/api/accounts';
  import HoloHumanSessionContentManager from './HoloHumanSessionContentManager.svelte';

  export let activeTab;
  export let session;
  export let clientApps;
  export let classesById;

  let clientAppVersion = '';
  let formattedSelected;
  let dateValue = utcStringToLocalDate(session.lessonDate);
  let hourValue = String(dateValue.getHours());
  let minuteValue = String(roundMinutesToNearestFive(dateValue.getMinutes())).padStart(2, '0');
  let isStudent = getAccountRole() === AccountRole.STUDENT;

  let clientAppName = null;
  $: {
    if (session) {
      if (session.clientAppVersion && session.clientAppVersion.length) {
        clientAppVersion = session.clientAppVersion;
      }

      // cleanup
      if (clientAppVersion === null || clientAppVersion === 'null') {
        clientAppVersion = '';
      }
      clientAppName = clientApps
        .filter((app) => app.clientAppId === session.clientAppId)
        .map((app) => app.clientAppName)
        .join('');
    }
  }

  $: className = Object.values(classesById)
    .filter((clazz) => clazz.classId === session.classId)
    .map((clazz) => clazz.className);

  $: lessonDateTime = utcStringToLocalDate(session.lessonDate).toLocaleString();
</script>

<TwoColumnSection>
  <div slot="left" hidden={activeTab !== 'Details'}>
    <div class="logo-row">
      <div class="logo-row__logo">
        <ClientAppLogo {clientAppName} />
        {#if clientAppVersion !== ''}
          <div class="gigxr-version-number">ver: {clientAppVersion}</div>
        {/if}
      </div>
      <div class="logo-row__content">
        <ContentRow>
          <Textfield
            input$id="session-name-field"
            class="gigxr-input"
            bind:value={session.sessionName}
            variant="filled"
            label="Session Name"
            disabled
          />
        </ContentRow>

        <ContentRow>
          <Select
            inputId="client-app-field"
            class="gigxr-input"
            bind:value={session.clientAppId}
            variant="filled"
            label="App"
            enhanced
            disabled
          >
            <Option value="" />
            {#each clientApps as app (app.clientAppId)}
              <Option value={app.clientAppId} selected={app.clientAppId === session.clientAppId}>
                {app.clientAppName}
              </Option>
            {/each}
          </Select>
        </ContentRow>

        <ContentRow>
          <Select
            inputId="class-field"
            class="gigxr-input"
            bind:value={session.classId}
            variant="filled"
            label="Class"
            enhanced
            disabled
          >
            <Option value="" />
            {#each Object.values(classesById) as clazz (clazz.classId)}
              <Option value={clazz.classId}>{clazz.className}</Option>
            {/each}
          </Select>
        </ContentRow>
      </div>
    </div>

    <ContentRow class="session-lesson-row">
      <div class="session-lesson-row__label">Lesson Start Date:</div>
      <GigXrDatepicker id="lesson-start-date-field" bind:dateValue disabled />
    </ContentRow>

    <ContentRow class="session-lesson-row">
      <div class="session-lesson-row__label">Lesson Start Time:</div>
      <GigXrTimepicker bind:hourValue bind:minuteValue disabled />
    </ContentRow>

    <ContentRow>
      <FilledTextArea
        id="session-description-field"
        bind:value={session.description}
        label="Description"
        ariaLabel="session-description"
        disabled
      />
    </ContentRow>

    {#if session.sessionNoteVisible}
      <ContentRow>
        <FilledTextArea
          id="session-notes-field"
          bind:value={session.sessionNote}
          label="Notes"
          ariaLabel="session-notes"
          disabled
        />
      </ContentRow>
    {/if}
  </div>

  <div slot="left" class="session-content-tab" hidden={activeTab !== 'Content'}>
    {#if Object.keys(session.hmdJson).length === 0}
      Your app generated content will appear here.
    {:else}
      {#if isUnity() && session.sessionStatus !== 'InProgress'}
        {#if isLegacyHoloPatient(session.clientAppId)}
          <SessionContentManager {session} />
        {:else if isLegacyHoloHuman(session.clientAppId)}
          <HoloHumanSessionContentManager {session} />
        {:else}
          <!-- Platform does not require a content manager but a placeholder has been added for future use. -->
          <!-- <PlatformSessionContentManager {session} /> -->
        {/if}
      {/if}
      <!-- <SessionLabels bind:hmdJson={session.hmdJson}/> -->
      {#if isLegacyHoloPatient(session.clientAppId)}
        <SessionScenesPanel
          clientAppId={session.clientAppId}
          bind:hmdJson={session.hmdJson}
          sessionCreatedById={session.createdById}
        />
      {:else if isLegacyHoloHuman(session.clientAppId)}
        <PlatformStagePanel bind:hmdJson={session.hmdJson} sessionCreatedById={session.createdById} />
      {:else if !(isStudent && session.clientApp.clientAppName === 'HoloScenarios')}
        <PlatformV3ContentPanel
          inputLabel={isLegacyHoloChem(session.clientAppId) ? 'Experiment' : 'Scenario'}
          bind:hmdJson={session.hmdJson}
        />
      {/if}
    {/if}

    <div><a class="" href="https://www.gigxr.com/credits" target="_blank" rel="noopener noreferrer">CREDITS</a></div>
  </div>

  <div slot="right">
    <SessionPermissionSelect bind:session disabled />

    <DateAccountComponent label="Created" utcDateString={session.createdOn} account={session.createdBy} />

    <DateAccountComponent label="Last Saved" utcDateString={session.modifiedOn} account={session.modifiedBy} />
  </div>
</TwoColumnSection>

<style>
  .logo-row__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1.5em;
  }

  .gigxr-version-number {
    margin: 10px auto;
    text-align: center;
  }

  @media (min-width: 1100px) {
    .logo-row {
      display: flex;
      justify-content: space-between;
    }

    .logo-row__logo {
      flex: 1;
      justify-content: start;
      margin-right: 1em;
    }

    .logo-row__content {
      flex: 3;
    }
  }

  :global(.session-lesson-row) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  :global(.session-lesson-row) .session-lesson-row__label {
    display: inline-block;
    flex: 1;
    font-weight: 700;
    padding-right: 5px;
  }

  @media (min-width: 1100px) {
    :global(.session-lesson-row) {
      width: 60%;
    }
  }
</style>
