<script>
  import { navigate } from 'svelte-routing';
  import Button, { Label, Icon } from '@smui/button';
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';

  let sessionMenu;
</script>

<div>
  <!-- <Button id="create-session-button" on:click={() => sessionMenu.setOpen(true)} variant="unelevated"> -->
  <Button id="create-session-button" on:click={() => navigate('/sessions/create-session-select-app')} variant="unelevated">

    <Label>Create New Session</Label>
    <Icon class="material-icons">add</Icon>
  </Button>

</div>

<style>
</style>
