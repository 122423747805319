import { authenticatedGet, authenticatedPatch, authenticatedPost, authenticatedPut, defaultHeaders } from '../api';
import { institutionIdStore, errorMessage } from '../../stores/core-store.js';
import { GmsError, RedirectError, SessionForbidError } from '../errors';
import { GENERIC_ERROR_MESSAGE } from '../constants';
import { get } from 'svelte/store';

/**
 * SessionDetailedView
 * @typedef {Object} SessionDetailedView
 * @property {string} sessionId
 * @property {string} sessionName
 * @property {string} description
 * @property {boolean} sessionPlan
 * @property {string} [lessonDate]
 * @property {SessionStatus} sessionStatus
 * @property {string} sessionPermission
 * @property {boolean} locked
 * @property {string} [instructorId]
 * @property {object} instructor TODO: ref instructor
 * @property {string} clientAppId
 * @property {string} [classId]
 * @property {object} [classEntity] TODO: ref class
 * @property {object} hmdJson
 * @property {boolean} saved
 * @property {string} lastActivity
 * @property {string} createdOn
 * @property {string} createdById
 * @property {string} modifiedOn
 * @property {string} modifiedById
 */

const SessionStatus = {
  INVALID: 'Invalid',
  ENDED: 'Ended',
  IN_PROGRESS: 'InProgress',
  ARCHIVED: 'Archived',
};

const SessionPermission = {
  PRIVATE: 'Private',
  OPEN_TO_INVITED_PARTICIPANTS: 'OpenToInvitedParticipants',
  OPEN_TO_CLASS: 'OpenToClass',
  OPEN_TO_DEPARTMENT: 'OpenToDepartment',
  OPEN_TO_INSTITUTION: 'OpenToInstitution',
  OPEN_TO_INSTITUTION_NON_STUDENTS: 'OpenToInstitutionNonStudents',
};

const SessionPermissionText = {
  Private: 'Private',
  OpenToInvitedParticipants: 'Open to Invited Participants',
  OpenToClass: 'Open to Class',
  OpenToDepartment: 'Open to Department',
  OpenToInstitution: 'Open to Institution',
  OpenToInstitutionNonStudents: 'Open to Institution (Non-Students)',
};

const permissionsThatRequireClasses = new Set([
  SessionPermission.OPEN_TO_INVITED_PARTICIPANTS,
  SessionPermission.OPEN_TO_CLASS,
  SessionPermission.OPEN_TO_DEPARTMENT,
]);

async function fetchSessions() {
  const response = await authenticatedGet('/sessions/all');
  if (response.status !== 200) {
    throw new GmsError(`Error fetching sessions! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data.filter((session) => !(!session.saved && session.sessionStatus !== 'InProgress'));
}

async function fetchAvailableSessions() {
  const response = await authenticatedGet('/sessions/available');
  if (response.status !== 200) {
    throw new GmsError(`Error fetching active sessions! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchSavedSessions(includeArchived = false) {
  const response = await authenticatedGet(`/sessions/saved?includeArchived=${includeArchived}`, {...defaultHeaders, 'api-version': '1.1'});
  if (response.status !== 200) {
    throw new GmsError(`Error fetching active sessions! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchSessionPlans(includeArchived = false) {
  // NOTE: hard-coding the API version header here
  // TODO: Please remove when we have a better way to doing this

  const response = await authenticatedGet(`/sessions/plans?includeArchived=${includeArchived}`,  {...defaultHeaders, 'api-version': '1.1'});
  if (response.status !== 200) {
    throw new GmsError(`Error fetching session plans! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchGigXrSessionPlans(includeArchived = false) {
  // NOTE: hard-coding the API version header here
  // TODO: Please remove when we have a better way to doing this
  const response = await authenticatedGet(`/sessions/plans/gigxr?includeArchived=${includeArchived}`, {...defaultHeaders, 'api-version': '1.1'});
  if (response.status !== 200) {
    throw new GmsError(`Error fetching session plans! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchSessionPlansForVersion(clientAppId, clientAppVersion) {
  // NOTE: hard-coding the API version header here
  // TODO: Please remove when we have a better way to doing this
  const response = await authenticatedGet(`/sessions/plans/version/${clientAppId}/${clientAppVersion}`, {...defaultHeaders, 'api-version': '1.1'});
  if (response.status !== 200) {
    throw new GmsError(`Error fetching session plans! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function getSessionPermissions() {
  const response = await authenticatedGet('/sessions/permissions');
  if (!response.ok) {
    throw new GmsError(`Error fetching session permissions! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchSession(sessionId) {
  const response = await authenticatedGet(`/sessions/${sessionId}`,{...defaultHeaders, 'api-version': '1.1'});
  if (response.status === 403) {
    throw new SessionForbidError(`You do not have access to this session! ${GENERIC_ERROR_MESSAGE}`);
  }

  if (response.status === 404) {
    throw new RedirectError('Session not found!', '/not-found');
  }

  if (response.status !== 200) {
    throw new GmsError(`Error fetching session! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchSessionPlan(sessionId) {

  const response = await authenticatedGet(`/sessions/plans/${sessionId}`,{...defaultHeaders, 'api-version': '1.1'});
  if (response.status === 404) {
    throw new RedirectError('Session plan not found!', '/not-found');
  }

  if (response.status !== 200) {
    throw new GmsError(`Error fetching session plan! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function createSession(session) {

  let headers;

  const validInput = session && session.sessionName && session.sessionName.length > 0 && session.clientAppId;

  if (!validInput) {
    throw new GmsError(`Session name and app are required!`);
  }

  const requestPayload = {
    ...session,
    institutionId: get(institutionIdStore),
    sessionStatus: 'Ended',
  };
  
  // make sure we got a clientAppVersion before we switch api-version
  // TODO: We should not allow for any Sessions or Session Plans without versions to be created
  headers = defaultHeaders;


  // NOTE: looks like there's ALWAYS a ClientAppID HoloScenarios ClientAppId is 5516eb34-611a-4eac-b2d6-834a8c1d4509
  // I hate hard-coding clientAppId here.. we need to fix this.
  if (session.clientAppId == '5516eb34-611a-4eac-b2d6-834a8c1d4509') {
    if (typeof session.clientAppVersion == 'undefined' || !session.clientAppVersion ) {
      throw new GmsError('HoloScenarios Session Plans must have a version!');
    }
    if (session.clientAppVersion && session.clientAppVersion.length > 1) {
      headers = {...defaultHeaders, 'api-version': '1.1'};
    } 
  }
  
  const response = await authenticatedPost('/sessions', requestPayload, headers);

  if (response.status !== 201) {
    throw new GmsError(`Error creating session! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newSession = json.data;

  return newSession;
}

async function copySession(session, sessionName = null) {

  console.log(session);

  let sessionNameQueryParam = '';
  if (sessionName) {
    sessionNameQueryParam = `&sessionName=${sessionName}`;
  }

  
  let headers = defaultHeaders;
  if (typeof session.clientAppVersion != 'undefined' && session.clientAppVersion) {
    // not sure why this might be indefined
    if (typeof session.clientAppVersion.length != 'undefined') {
      // also make sure that the string length is greater than 1
      if (session.clientAppVersion.length > 1) {
        headers = {...defaultHeaders, 'api-version': '1.1'};
      }
    }  
  }

  const response = await authenticatedPost(`/sessions?sourceSessionId=${session.sessionId}${sessionNameQueryParam}`, null, headers);

  if (response.status !== 201) {
    throw new GmsError(`Error creating session! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newSession = json.data;

  return newSession;
}

async function editSession(session) {
  const validInput = session && session.sessionName && session.sessionName.length > 0 && session.clientAppId;

  if (!validInput) {
    throw new GmsError(`Session name and app are required!`);
  }

  const requestPayload = {
    ...session,
    saved: true,
  };

  //HTTP PUT for sessions intentionally does not support v1.1 API to prevent users from changing the clientAppVersion
  const response = await authenticatedPut(`/sessions/${session.sessionId}`, requestPayload, defaultHeaders);

  if (response.status !== 200) {
    throw new GmsError(`Error editing session! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const editedSession = json.data;

  return editedSession;
}

async function archiveSession(sessionId) {
  const patches = [
    {
      sessionId,
      sessionStatus: 'Archived',
    },
  ];

  await patchSessions(patches);
}

async function unarchiveSession(sessionId) {
  const patches = [
    {
      sessionId,
      sessionStatus: 'Ended',
    },
  ];

  await patchSessions(patches);
}

async function patchSessions(patches) {
  const requestPayload = [...patches];

  const response = await authenticatedPatch(`/sessions`, requestPayload);

  if (!response.ok) {
    throw new GmsError(`Error patching sessions! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function createSessionPlan(session, sourceSessionId) {
  const validInput = session && session.sessionName && session.sessionName.length > 0 && session.clientAppId;
  let headers = defaultHeaders;
  if (!validInput) {
    throw new GmsError('Session name and app are required!');
  }

  // pesky case here.. need to nest the logic for this to work
  if (session.clientApp.clientAppName == 'HoloScenarios') {
    headers = {...defaultHeaders, 'api-version': '1.1'};
    // If we have a sourceSessionId the API will automatically the target with the source version
    if(sourceSessionId == 'undefined' || typeof sourceSessionId == 'undefined') {
      // If we have no sourceSessionId and no clientAppVersion, abort
      if (typeof session.clientAppVersion == 'undefined' || !session.clientAppVersion ) {
        throw new GmsError('HoloScenarios Session Plans must have a version!');
      }
    }
  }

  const requestPayload = {
    ...session,
  };

  //alert(`MODULE LIST: ${session.moduleList}`);

  if (sourceSessionId == 'undefined' || typeof sourceSessionId == 'undefined') {
    sourceSessionId = '';
  }

  const response = await authenticatedPost(`/sessions/plans?sourceSessionId=${sourceSessionId}`, requestPayload, headers);

  if (response.status !== 201) {
    throw new GmsError(`Error creating session plan! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newSessionPlan = json.data;

  return newSessionPlan;
}

async function createSessionPlanFromSession(sessionId) {
  let headers = defaultHeaders;
  let prevSession;
  [ prevSession ] = await Promise.all([ fetchSession(sessionId) ]);

  if (prevSession.clientAppVersion && prevSession.clientAppVersion.length) {
    headers = {...defaultHeaders, 'api-version': '1.1'};
  }

  //console.log(`MODULE LIST: ${prevSession.moduleList}`);
  const response = await authenticatedPost(`/sessions/plans?sourceSessionId=${sessionId}`, null, headers);

  if (!response.ok) {
    throw new GmsError(`Error creating session plan! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newSessionPlan = json.data;

  return newSessionPlan;
}

async function editSessionPlan(session) {
  const validInput = session && session.sessionName && session.sessionName.length > 0 && session.clientAppId;

  if (!validInput) {
    throw new GmsError('Session name and app are required!');
  }

  const requestPayload = {
    ...session,
  };

  let headers = defaultHeaders;

  const response = await authenticatedPut(`/sessions/plans/${session.sessionId}`, requestPayload, headers);

  if (response.status !== 200) {
    throw new GmsError(`Error editing session plan! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const editedSession = json.data;

  return editedSession;
}

async function archiveSessionPlan(sessionId) {
  const patches = [
    {
      sessionId,
      sessionStatus: 'Archived',
    },
  ];

  await patchSessionPlans(patches);
}

async function unarchiveSessionPlan(sessionId) {
  const patches = [
    {
      sessionId,
      sessionStatus: 'Ended',
    },
  ];

  await patchSessionPlans(patches);
}

async function patchSessionPlans(patches) {
  const requestPayload = [...patches];

  const response = await authenticatedPatch(`/sessions/plans`, requestPayload);

  if (!response.ok) {
    throw new GmsError(`Error patching session plans! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function fetchSessionParticipants(sessionId) {
  const response = await authenticatedGet(`/sessions/${sessionId}/participants`);
  if (response.status !== 200) {
    throw new GmsError(`Error fetching session participants! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function updateSessionParticipants(sessionId, participants = []) {
  const requestPayload = {
    participants,
  };

  const response = await authenticatedPut(`/sessions/${sessionId}/participants`, requestPayload);
  if (!response.ok) {
    throw new GmsError(`Error adding session participants! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function updateSessionParticipant(sessionId, accountId, sessionParticipantStatus) {
  const requestPayload = {
    sessionParticipantStatus,
  };

  const response = await authenticatedPut(`/sessions/${sessionId}/participants/${accountId}`, requestPayload);
  if (!response.ok) {
    throw new GmsError(`Error editing session participants! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function sendSessionInvite(sessionId, accountId) {
  const response = await authenticatedPost(`/sessions/${sessionId}/participants/${accountId}/invitation`);
  if (!response.ok) {
    throw new GmsError(`Error sending session invitation! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function sendSessionUninvite(sessionId, accountId) {
  const response = await authenticatedPost(`/sessions/${sessionId}/participants/${accountId}/uninvitation`);
  if (!response.ok) {
    throw new GmsError(`Error notifying user! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

// HoloPatient 2.0
async function fetchSessionClips() {
  const response = await authenticatedGet('/sessions/clips');
  if (!response.ok) {
    throw new GmsError(`Error fetching session clips! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

// HoloPatient 2.0
async function fetchSessionClipAliases() {
  const response = await authenticatedGet('/sessions/clip-aliases');
  if (!response.ok) {
    throw new GmsError(`Error fetching session clip names! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

// Platform
async function fetchSessionResources() {
  const response = await authenticatedGet('/sessions/resources');
  if (!response.ok) {
    throw new GmsError(`Error fetching session resources! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

// Platform
async function fetchSessionResourceAliases() {
  const response = await authenticatedGet('/sessions/resource-aliases');
  if (!response.ok) {
    // we're going to return an empty array of resources if none.
    return {"resources": []};
  }

  const json = await response.json();

  return json.data;
}

export {
  SessionStatus,
  SessionPermission,
  SessionPermissionText,
  permissionsThatRequireClasses,
  fetchSessions,
  fetchSavedSessions,
  fetchAvailableSessions,
  fetchSessionPlans,
  fetchGigXrSessionPlans,
  fetchSessionPlansForVersion,
  getSessionPermissions,
  fetchSession,
  fetchSessionPlan,
  createSession,
  copySession,
  editSession,
  archiveSession,
  unarchiveSession,
  patchSessions,
  createSessionPlan,
  createSessionPlanFromSession,
  editSessionPlan,
  archiveSessionPlan,
  unarchiveSessionPlan,
  patchSessionPlans,
  fetchSessionParticipants,
  updateSessionParticipants,
  updateSessionParticipant,
  sendSessionInvite,
  sendSessionUninvite,
  fetchSessionClips,
  fetchSessionClipAliases,
  fetchSessionResources,
  fetchSessionResourceAliases,
};
