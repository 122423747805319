<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { title, breadcrumbPaths, institutionIdStore, snackbar, snackbarMessage } from '../stores/core-store';
  import { fetchInstitutionConfig, editInstitutionConfig } from '../util/api/institutions';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';
  import Switch from '@smui/switch';
  import FormField from '@smui/form-field';
  import LoadingView from '../components/LoadingView.svelte';

  title.set('Support');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Support',
      location: '/support',
    },
  ]);

  let loading = true;
  let institutionConfig = undefined;

  onMount(async () => {
    const institutionId = get(institutionIdStore);
    institutionConfig = await fetchInstitutionConfig(institutionId);
    loading = false;
  });

  async function allowGigXrAccessChangeHandler(event) {
    institutionConfig = await editInstitutionConfig(institutionConfig);
    snackbarMessage.set('Settings updated!');
    $snackbar.open();
  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
    <div class="support-row">
      <div class="support-row__left"><span class="support-row__header">Answers to frequently asked questions (FAQs)</span></div>
      <div class="support-row__right"><a href="https://help.gigxr.com/knowledge" target="_blank" rel="noopener noreferrer">GigXR Help Center</a></div>
    </div>

      <div class="support-row">
        <div class="support-row__left">
          <span class="support-row__header">Allow GIGXR to access institution data for support</span>
          <br />
          Permission can be turned on and off at any time.
        </div>
        <div class="support-row__right">
          <FormField inputId="allow-gigxr-access-field">
            <Switch
              bind:checked={institutionConfig.allowGigXrAccess}
              class="gigxr-switch"
              on:change={allowGigXrAccessChangeHandler}
            />
            <span slot="label">{institutionConfig.allowGigXrAccess ? 'On' : 'Off'}</span>
          </FormField>
        </div>
      </div>

      <div class="support-row">
        <div class="support-row__left"><span class="support-row__header">Contact GIGXR support via email</span></div>
        <div class="support-row__right">
          <a id="gigxr-support-email-link" href="mailto:support@gigxr.com">support@gigxr.com</a>
        </div>
      </div>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .primary-content .support-row a { font-weight: bold; }
  .support-row {
    margin-bottom: 2em;
  }

  .support-row__header {
    font-weight: 700;
  }

  .support-row__right {
    margin-top: 1em;
  }

  @media (min-width: 1100px) {
    .support-row {
      display: flex;
    }

    .support-row__left {
      flex: 1;
    }

    .support-row__right {
      flex: 1;
      margin-top: 0;
    }
  }
</style>
