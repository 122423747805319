<script>
    import { onMount, onDestroy } from 'svelte';
    import { title, breadcrumbPaths } from '../stores/core-store';
    import PrimaryContent from '../components/PrimaryContent.svelte';
    import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';
    import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
    import LoadingView from '../components/LoadingView.svelte';
    export let showRole;
    let sections = [];
    title.set('Resources');
    breadcrumbPaths.set([
      {
        name: 'Dashboard',
        location: '/',
      },
      {
        name: 'Resources',
        location: '/resources',
      },
    ]);

    let loading = true;
    onMount(async () => {

    await Promise.all([refreshResourcePage()]);

  });

  async function refreshResourcePage() {
    const data = fetchresourcesData();
  }

  async function fetchresourcesData() {
    const cachbuster = new Date().getTime();
    // The URL is hard coded here because we don't have a DEV site
    const resourceURL = 'https://www.gigxr.com/wp-json/gigxr-api/v1/resources?'+cachbuster;
    const response = await fetch(resourceURL);
    const data = (await response.json()).slice(0,20);
    loading = false;
    sections = data;
  }

  </script>
  
  <SecondaryBackgroundWrapper>
    <PrimaryContent>
        <p>Find links to the most current support documentation and materials below. These will be updated as new versions and new products are released. You may also find answers to frequently asked questions (FAQs) in the <a href="https://help.gigxr.com/knowledge" target="_blank" rel="noopener noreferrer">GigXR Help Center</a>.</p>
        {#if loading}
              <LoadingView withMargin />
        {:else}
                {#if sections.length > 0}
                {#each sections as section}
                <h2>{section.section}</h2>
                
                <DataTable ariaLabel="Institutions" class="institutions-data-table {showRole}" >
                    <Head class="hide-now">
                      <Row>
                        <Cell class="institutions-data-table__name-column">Document</Cell>
                        <Cell class="institutions-data-table__name-column">Modified Date</Cell>
                        <Cell class="institutions-data-table__name-column">Version</Cell>
                      </Row>
                    </Head>
                    <Body>
                      
                        {#if section.links.length > 0}
                            {#each section.links as item}
                                <Row class="{item.should_display_to_students === true ? 'show-all':'hide'}">

                                    <Cell><a href="{item.media.is_a_video === true ? ''+item.media.vimeo_url : ''+item.media.media_library}" title="Download" target="_blank"  rel="noopener noreferrer">{item.title}</a></Cell>
                                    <Cell class="hide-now">{item.modified_date}</Cell>
                                    <Cell class="hide-now">{item.version}</Cell>
                                </Row>
                            {/each}
                        {/if}
                      
                    </Body>
                </DataTable>
                {/each}
                {/if}
        {/if}
    </PrimaryContent>
  </SecondaryBackgroundWrapper>
  
  <style>
    :global(.institutions-data-table) {width: 100%; background-color: #eef2f4; border: 0;}
    :global(.student tr.hide) { display:none;}
    :global(.institutions-data-table .hide-now) { display: none;}
    .primary-content p a { font-weight: bold; }
  </style>