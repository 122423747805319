<script>
  import Button from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Tab, { Label } from '@smui/tab';
  import TabBar from '@smui/tab-bar';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import ClientAppLogo from '../../components/client-apps/ClientAppLogo.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import BreadcrumbCreateSession from '../../components/BreadcrumbCreateSession.svelte';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import JoinSessionButton from '../../components/sessions/JoinSessionButton.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import { breadcrumbPaths, role, title } from '../../stores/core-store';
  import { getAccountId } from '../../util/account';
  import { fetchLicensedClientApps } from '../../util/api/client-apps';
  import { roundMinutesToNearestFive, utcStringToLocalDate } from '../../util/dates';

  title.set('Create Session');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session List',
      location: '/sessions/list',
    },
    {
      name: 'Create Session',
      location: `/sessions/create`,
    },
  ]);

  let tabs = ['Details'];

  let licensedClientApps = [];
  let discardChangesDialog;

  let formattedSelected;
  let dateValue;
  let hourValue;
  let minuteValue;

  let fromPlanId = null;

  const date = new Date();
  date.setSeconds(0);
  date.setMilliseconds(0);
  let todayIso = date.toISOString();
  todayIso = todayIso.substring(0, todayIso.length - 1);

  let lastSavedSession = {
    sessionName: '',
    clientAppId: '',
    classId: '',
    instructorId: '',
    lessonDate: todayIso,
    description: '',
    sessionPermission: 'Private',
    saved: true,
    locked: false,
  };

  if ($role === 'Instructor') {
    lastSavedSession.instructorId = getAccountId();
  }

  let session = null;

  $: {
    if (session) {
      const newDate = dateValue;
      newDate.setHours(hourValue, minuteValue);
      session.lessonDate = newDate.toISOString();
    }
  }

  let clientAppName = null;
  $: {
    if (session) {
      clientAppName = licensedClientApps
        .filter((app) => app.clientAppId === session.clientAppId)
        .map((app) => app.clientAppName)
        .join('');
    }
  }

  onMount(async () => {
    [licensedClientApps] = await Promise.all([fetchLicensedClientApps()]);
    window.addEventListener('resize', function() {location.reload();});

    session = { ...lastSavedSession };
    loadSessionToUi();
  });

  function setBodyClass() {
    document.body.classList.add('select-app');
  }

  function loadSessionToUi() {
    dateValue = utcStringToLocalDate(session.lessonDate);
    hourValue = String(dateValue.getHours());
    minuteValue = String(roundMinutesToNearestFive(dateValue.getMinutes())).padStart(2, '0');
    resizeDropDown();
    setBodyClass();
  }

  async function selectApp() {
    navigate(`/sessions/create-session-select-plan/${session.clientAppId}`);
  }

  function resizeDropDown() {
    setTimeout(function() {
      const guidewidth = document.getElementById("client-app-field-smui-selected-text");
      const dropdown = document.querySelector("div.mdc-menu-surface.mdc-menu.mdc-select__menu"); 
      if (guidewidth && guidewidth.offsetWidth) {
        dropdown.style.width = guidewidth.offsetWidth + 'px';
      } 
    },100)

  }

</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if session}
            <ContentRow>
              <BreadcrumbCreateSession stage=1 stageLabels={['Select App', 'Select Session Plan', 'Customize Session']}/>
              <Select
                inputId="client-app-field"
                class="gigxr-input app-select"
                bind:value={session.clientAppId}
                variant="filled"
                label="App"
                enhanced
              >
                {#each licensedClientApps as app (app.clientAppId)}
                  <Option value={app.clientAppId} selected={app.clientAppId === session.clientAppId}>
                    {app.clientAppName}
                  </Option>
                {/each}
                {#if licensedClientApps.length == 0}
                  <Option disabled on:click={(event) => event.stopPropagation()}>No Licensed Apps</Option>
                {/if}
              </Select>
            </ContentRow>
            {#if session.clientAppId}
              <ContentRowCenteredOnMobile class="center-button-flex">
                <Button
                  id="create-session-save-button"
                  class="gigxr-button"
                  variant="unelevated"
                  on:click={() => selectApp()}
                >
                  <Label>Next</Label>
                </Button>
              </ContentRowCenteredOnMobile>
            {/if}
    {:else}
      <LoadingView />
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .create-select-app-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .row {
    width: auto;
  }

  :global(.app-select) {
    width: 60% !important;
  }

  :global(.center-button-flex) {
    display: flex;
    justify-content: center;
  }

  :global(.session-date-button) {
    width: 100%;
    background: var(--gigxr-theme-primary-1c) !important;
  }

  @media (min-width: 1100px) {
  }

  :global(.session-lesson-row) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 1100px) {
    :global(.session-lesson-row) {
      width: 60%;
    }
  }
</style>
