import { NON_BREAKING_SPACE } from '../util/constants.js';

function utcStringToLocalDate(utcString) {
  if (utcString && !utcString.includes('Z')) {
    utcString = `${utcString}Z`;
  }
  return new Date(utcString);
}

function utcStringToLocalDateStandard(utcString) {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };

  return utcStringToLocalDate(utcString).toLocaleString(undefined, options);
}

function sessionCardDisplayedDate(session) {
  switch (session.sessionStatus) {
    case 'InProgress':
      return 'In Progress';
    case 'New':
    case 'Ended':
      const lessonDate = new Date(session.lessonDate).getTime();
      const currentDate = new Date().getTime();

      if (currentDate > lessonDate) {
        const endedOrScheduled = session.sessionStatus === 'New' ? 'Scheduled' : 'Ended';
        const timeDiffInSeconds = Math.floor((currentDate - lessonDate) / 1000);
        if (timeDiffInSeconds < 60) {
          return `${endedOrScheduled} ${timeDiffInSeconds} second${timeDiffInSeconds !== 1 ? 's' : ''} ago`;
        } else if (timeDiffInSeconds < 3600) {
          // Less than one hour - 60 * 60 seconds
          const mins = Math.floor(timeDiffInSeconds / 60);
          return `${endedOrScheduled} ${mins} minute${mins !== 1 ? 's' : ''} ago`;
        } else if (timeDiffInSeconds < 86400) {
          // Less than one day - 24 * 60 * 60 seconds
          const hours = Math.floor(timeDiffInSeconds / 3600);
          return `${endedOrScheduled} ${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (timeDiffInSeconds < 31536000) {
          // Less than a year (365 days) - 365 * 24 * 60 * 60 seconds
          return `${endedOrScheduled} on ${utcStringToLocalDateStandard(session.lessonDate)}`;
        }
      } else {
        const timeDiffInSeconds = Math.floor((lessonDate - currentDate) / 1000);
        if (timeDiffInSeconds < 60) {
          return `Starts in ${timeDiffInSeconds} second${timeDiffInSeconds !== 1 ? 's' : ''}`;
        } else if (timeDiffInSeconds < 3600) {
          // Less than one hour - 60 * 60 seconds
          const mins = Math.floor(timeDiffInSeconds / 60);
          return `Starts in ${mins} minute${mins !== 1 ? 's' : ''}`;
        } else if (timeDiffInSeconds < 86400) {
          // Less than one day - 24 * 60 * 60 seconds
          const hours = Math.floor(timeDiffInSeconds / 3600);
          return `Starts in ${hours} hour${hours !== 1 ? 's' : ''}`;
        } else {
          const days = Math.floor(timeDiffInSeconds / 86400);
          return `Starts in ${days} day${days !== 1 ? 's' : ''}`;
        }
      }
    default:
      return NON_BREAKING_SPACE;
  }
}

class Iso8601RegExp extends RegExp {
  constructor() {
    // https://stackoverflow.com/questions/3143070/javascript-regex-iso-datetime
    super(
      /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
      'g',
    );
  }
}

function replaceUtcStringsWithLocalDate(string) {
  const regExp = new Iso8601RegExp();
  return string.replace(regExp, function (match) {
    return utcStringToLocalDate(match).toLocaleString();
  });
}

function datesEqual(dateA, dateB) {
  return (
    dateA &&
    dateB &&
    dateA.getUTCFullYear() === dateB.getUTCFullYear() &&
    dateA.getUTCDate() === dateB.getUTCDate() &&
    dateA.getUTCDay() === dateB.getUTCDay()
  );
}

function hoursEqual(hour, date) {
  hour = String(hour);

  return hour && date && hour === String(date.getHours());
}

function minutesEqual(minutes, date) {
  minutes = Number(minutes);

  return date && minutes === Number(date.getMinutes());
}

function isDateToday(date) {
  const today = new Date();
  return date.toDateString() === today.toDateString();
}

function isDateInMostRecentDays(date, days) {
  const deltaDate = new Date();
  deltaDate.setDate(deltaDate.getDate() + days);

  if (days > 0) {
    return (date.getTime() < deltaDate.getTime() && date.getTime() > Date.now()) || isDateToday(date);
  }

  if (days < 0) {
    return (date.getTime() > deltaDate.getTime() && date.getTime() < Date.now()) || isDateToday(date);
  }

  return true;
}

function getShortDateString(date) {
  const d = new Date(date);
  let dm = String(d.getMonth());
  let dd = String(d.getDay());
  let dy = String(d.getFullYear()).slice(2);
  return dm + '/' + dd + '/' + dy;
}

function roundMinutesToNearestFive(minutes) {
  return Math.round((minutes * 12) / 60) * 5;
}

export {
  utcStringToLocalDate,
  sessionCardDisplayedDate,
  utcStringToLocalDateStandard,
  replaceUtcStringsWithLocalDate,
  getShortDateString,
  datesEqual,
  hoursEqual,
  minutesEqual,
  isDateToday,
  isDateInMostRecentDays,
  roundMinutesToNearestFive,
};
