<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import BreadcrumbCreateSession from '../../components/BreadcrumbCreateSession.svelte';
  import DesktopListPageControls from '../../components/DesktopListPageControls.svelte';
  import FilterButton from '../../components/FilterButton.svelte';
  import FilterComponent from '../../components/FilterComponent.svelte';
  import ListPageCardGridItem from '../../components/ListPageCardGridItem.svelte';
  import ListPageCount from '../../components/ListPageCount.svelte';
  import ListPageNoResultsMessage from '../../components/ListPageNoResultsMessage.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import SavedSessionCard from '../../components/sessions/SavedSessionCard.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import { breadcrumbPaths, role, title } from '../../stores/core-store';
  import { getAccountId } from '../../util/account';
  import { fetchClientAppVersions, fetchLicensedClientApps } from '../../util/api/client-apps';
  import { fetchSessionPlans } from '../../util/api/sessions';
  import { roundMinutesToNearestFive, utcStringToLocalDate } from '../../util/dates';
  import { SessionPlanFilterIncludeVersionsLatestDefaultSet, SessionPlanFilterIncludeVersionsSet, SessionPlanSortBySet } from '../../util/filters/session-plan-list-filters';
  export let clientAppId;

  title.set('Create Session');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session List',
      location: '/sessions/list',
    },
    {
      name: 'Create Session',
      location: `/sessions/create`,
    },
  ]);

  let licensedClientApps = [];
  let sessionPlans = [];
  let loading = true;
  let filterComponent;
  let filteredSessions = [];
  let filterBySets = [];
  let versions = [];
  let keyPlan = undefined;

  let dateValue;
  let hourValue;
  let minuteValue;

  const date = new Date();
  date.setSeconds(0);
  date.setMilliseconds(0);
  let todayIso = date.toISOString();
  todayIso = todayIso.substring(0, todayIso.length - 1);

  let lastSavedSession = {
    sessionName: '',
    clientAppId: '',
    classId: '',
    instructorId: '',
    lessonDate: todayIso,
    description: '',
    sessionPermission: 'Private',
    saved: true,
    locked: false,
  };

  if ($role === 'Instructor') {
    lastSavedSession.instructorId = getAccountId();
  }

  let session = null;

  $: {
    if (session) {
      const newDate = dateValue;
      newDate.setHours(hourValue, minuteValue);
      session.lessonDate = newDate.toISOString();
    }
  }

  let clientAppName = null;
  $: {
    if (session) {
      clientAppName = licensedClientApps
        .filter((app) => app.clientAppId === session.clientAppId)
        .map((app) => app.clientAppName)
        .join('');
    }
  }

  onMount(async () => {
    [licensedClientApps, versions, sessionPlans] = await Promise.all([
      fetchLicensedClientApps(),
      fetchClientAppVersions(clientAppId),
      fetchSessionPlans(),
    ]);

    session = { ...lastSavedSession };
    session.clientAppId = clientAppId;
    sessionPlans = sessionPlans.filter((x) => x.clientAppId == clientAppId);

    versions = [... new Set(sessionPlans.map(x => x.clientAppVersion).filter(x => x != null))];
    if(versions.length == 0) {
      filterBySets = [];
    } else {
      filterBySets = [SessionPlanFilterIncludeVersionsLatestDefaultSet(versions)];
    }
    // // If this application doesn't have any versions, don't filter by it
    // if (versions.at(0) != undefined) {
    //   sessionPlans = sessionPlans.filter(x => x.clientAppVersion == versions.at(0))
    // }

    // HoloHuman and HoloPatient have a 'default' Session Plan that is used to create new session plans
    // We want to find it, pin it and remove it from the session plan list
    keyPlan = sessionPlans.find((x) => x.sessionName == 'Empty Session' && x.gigXrBranded == 1);
    sessionPlans = sessionPlans.filter((x) => x != keyPlan);

    loading = false;
    loadSessionToUi();
  });

  function loadSessionToUi() {
    dateValue = utcStringToLocalDate(session.lessonDate);
    hourValue = String(dateValue.getHours());
    minuteValue = String(roundMinutesToNearestFive(dateValue.getMinutes())).padStart(2, '0');
  }

  async function selectPlan(sessionId) {
    navigate(`/sessions/create?fromPlanId=${sessionId}`);
  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <BreadcrumbCreateSession stage="2" stageLabels={['Select App', 'Select Session Plan', 'Customize Session']} />
    <DesktopListPageControls>
      <div slot="left">
        <!-- <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} /> -->
        <ListPageCount
          {loading}
          count={filteredSessions.length}
          singularLabel="Session Plan"
          pluralLabel="Session Plans"
        />
        <FilterButton label="Filters" on:click={filterComponent.toggle} />
      </div>
    </DesktopListPageControls>

    <FilterComponent
      bind:this={filterComponent}
      unfilteredList={sessionPlans}
      bind:filteredList={filteredSessions}
      sortBySet={SessionPlanSortBySet()}
      {filterBySets}
    />
    {#if session}
      <TwoColumnSection>
        <div slot="left">
          <div class="logo-row">
            <div class="session-list">
              {#if loading}
                <LoadingView withMargin />
              {:else if sessionPlans.length === 0 && !keyPlan}
                <ListPageNoResultsMessage>
                  <h3>There are no session plans to display.</h3>
                </ListPageNoResultsMessage>
              {:else}
                {#if keyPlan}
                  <ListPageCardGridItem>
                    <SavedSessionCard
                      class="gigxr-card gigxr-key-card"
                      session={keyPlan}
                      compact={false}
                      enableActions={false}
                      clickHandler={() => selectPlan(keyPlan.sessionId)}
                    />
                  </ListPageCardGridItem>
                {/if}
                {#each filteredSessions as plan (plan.sessionId)}
                  <ListPageCardGridItem>
                    <SavedSessionCard
                      session={plan}
                      compact={false}
                      enableActions={false}
                      clickHandler={() => selectPlan(plan.sessionId)}
                    />
                  </ListPageCardGridItem>
                {/each}

                <!-- {#if sessionPlans.length > 0}
                    <div class="mobile-sessions-link">
                      <a
                        id="mobile-view-more-session-plans-link"
                        href="/sessions/plans/list"
                        class="gigxr-link dashboard-view-more-button"
                        use:link
                      >View More</a>
                    </div>
                  {/if} -->
              {/if}
            </div>
          </div>
        </div>

        <div slot="right" />
      </TwoColumnSection>
    {:else}
      <LoadingView />
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  :global(.session-date-button) {
    width: 100%;
    background: var(--gigxr-theme-primary-1c) !important;
  }

  @media (min-width: 1100px) {
    .logo-row {
      display: flex;
      justify-content: space-between;
    }
  }

  :global(.session-lesson-row) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .session-list {
    display: grid;
    column-gap: 30px;
    justify-items: center;
  }

  @media (min-width: 768px) and (max-width: 1099px) {
    .session-list {
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
    }
  }

  @media (min-width: 1100px) {
    .session-list {
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
    }
  }

  @media (min-width: 1100px) {
    :global(.session-lesson-row) {
      width: 60%;
    }
  }
</style>
