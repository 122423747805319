<script>
  import Button from '@smui/button';
  import Select, { Option } from '@smui/select';
  import { Label } from '@smui/tab';
  import Textfield from '@smui/textfield';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import ClientAppLogo from '../../components/client-apps/ClientAppLogo.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import DateAccountComponent from '../../components/DateAccountComponent.svelte';
  import FilledTextArea from '../../components/FilledTextArea.svelte';
  import BreadcrumbCreateSession from '../../components/BreadcrumbCreateSession.svelte';
  import GigXrDatepicker from '../../components/GigXrDatepicker.svelte';
  import GigXrTimepicker from '../../components/GigXrTimepicker.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import SessionPermissionSelect from '../../components/sessions/SessionPermissionSelect.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import { breadcrumbPaths, role, snackbar, snackbarMessage, title } from '../../stores/core-store';
  import { getAccountId } from '../../util/account';
  import { fetchClasses } from '../../util/api/classes';
  import { fetchLicensedClientApps } from '../../util/api/client-apps';
  import { createSession, createSessionPlan, fetchSessionPlan, SessionPermission } from '../../util/api/sessions';
  import { roundMinutesToNearestFive, utcStringToLocalDate } from '../../util/dates';
  import { getQueryParameter } from '../../util/util';

  title.set('Create Session');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session List',
      location: '/sessions/list',
    },
    {
      name: 'Create Session',
      location: `/sessions/create`,
    },
  ]);

  export let clientAppId;
  let tabs = ['Details'];
  let clientAppVersion;
  let licensedClientApps = [];
  let classesById = {};

  let discardChangesDialog;

  let formattedSelected;
  let dateValue;
  let hourValue;
  let minuteValue;
  let sessionPlanName = '';

  let fromPlanId = null;

  const date = new Date();
  date.setSeconds(0);
  date.setMilliseconds(0);
  let todayIso = date.toISOString();
  todayIso = todayIso.substring(0, todayIso.length - 1);

  let lastSavedSession = {
    sessionName: '',
    clientAppVersion: '',
    clientAppId: '',
    classId: '',
    instructorId: '',
    lessonDate: todayIso,
    description: '',
    sessionPermission: 'Private',
    saved: true,
    locked: false,
  };

  if ($role === 'Instructor') {
    lastSavedSession.instructorId = getAccountId();
  }

  let session = null;

  $: {
    if (session) {
      const newDate = dateValue;
      newDate.setHours(hourValue, minuteValue);
      session.lessonDate = newDate.toISOString();
    }
  }

  let clientAppName = null;
  $: {
    if (session) {
      clientAppName = licensedClientApps
        .filter((app) => app.clientAppId === session.clientAppId)
        .map((app) => app.clientAppName)
        .join('');
    }
  }

  function KeyPress(e) {
    var evtobj = window.event ? event : e;
    // Import session/plan
    if (evtobj.keyCode == 69 && evtobj.ctrlKey && evtobj.shiftKey) {
      var input = document.createElement('input');
      input.type = 'file';
      input.onchange = (e) => {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = (e) => {
          var data = JSON.parse(e.target.result);
          lastSavedSession.sessionName = data?.sessionName ?? lastSavedSession.sessionName;
          lastSavedSession.description = data?.description ?? lastSavedSession.description;
          lastSavedSession.clientAppId = data?.clientAppId ?? lastSavedSession.clientAppId;
          lastSavedSession.saved = data?.saved ?? lastSavedSession.saved;
          lastSavedSession.locked = data?.locked ?? lastSavedSession.locked;
          lastSavedSession.clientAppVersion = data?.clientAppVersion ?? lastSavedSession.clientAppVersion;
          lastSavedSession.hmdJson = data?.hmdJson ?? lastSavedSession.hmdJson;
          console.log(lastSavedSession);
          session = { ...lastSavedSession };
          loadSessionToUi();
        };
      };
      input.click();
    }
  }

  document.onkeydown = KeyPress;

  onMount(async () => {
    let classes;
    [licensedClientApps, classes] = await Promise.all([fetchLicensedClientApps(), fetchClasses()]);

    classes.forEach((clazz) => (classesById[clazz.classId] = clazz));

    fromPlanId = getQueryParameter('fromPlanId');
    if (fromPlanId) {
      const sessionPlan = await fetchSessionPlan(fromPlanId);
      // see if we have a clientAppVersion
      if (typeof sessionPlan.clientAppVersion != 'undefined' && sessionPlan.clientAppVersion != 'null') {
        lastSavedSession.clientAppVersion = sessionPlan.clientAppVersion;
      } else {
        lastSavedSession.clientAppVersion = '';
      }
      sessionPlanName = sessionPlan.sessionName;
      // lastSavedSession.sessionName = sessionPlan.sessionName;
      lastSavedSession.sessionName = '';
      lastSavedSession.clientAppId = sessionPlan.clientAppId;
      lastSavedSession.classId = sessionPlan.classId;
      lastSavedSession.instructorId = sessionPlan.instructorId;
      lastSavedSession.description = sessionPlan.description;
      lastSavedSession.sessionPermission = SessionPermission.PRIVATE;
      lastSavedSession.hmdJson = sessionPlan.hmdJson;
      // title.set('Create Session From Session Plan');
      // errorMessage.set('You are creating a new Session from the selected Session Plan');
    } else if (clientAppId) {
      // lastSavedSession.clientAppId = licensedClientApps.find(x => x.clientAppId == clientAppId)?.clientAppId;
      fromPlanId = lastSavedSession.clientAppId = licensedClientApps.find(
        (x) => x.clientAppId == clientAppId,
      )?.clientAppId;
    }

    session = { ...lastSavedSession };
    loadSessionToUi();
  });

  function loadSessionToUi() {
    if (typeof session.clientAppVersion != 'undefined' && typeof session.clientAppVersion != 'null') {
      clientAppVersion = session.clientAppVersion;
    } else {
      clientAppVersion = '';
    }

    dateValue = utcStringToLocalDate(session.lessonDate);
    hourValue = String(dateValue.getHours());
    minuteValue = String(roundMinutesToNearestFive(dateValue.getMinutes())).padStart(2, '0');
  }

  function discardChanges() {
    session = { ...lastSavedSession };
    loadSessionToUi();
  }

  async function save() {
    const newSession = await createSession(session);
    snackbarMessage.set('Session created!');
    $snackbar.open();
    navigate(`/sessions/view/${newSession.sessionId}`);
  }

  async function saveAndAddToSessionPlans() {
    const newSession = await createSession(session);

    const sessionPlan = await createSessionPlan(newSession, newSession.sessionId);
    snackbarMessage.set('Session created and added to session plans!');
    $snackbar.open();
    navigate(`/sessions/view/${newSession.sessionId}`);
  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if session}
      {#if fromPlanId}
        <BreadcrumbCreateSession stage="3" stageLabels={['Select App', 'Select Session Plan', 'Customize Session']} />
      {/if}
      <TwoColumnSection>
        <div slot="left">
          <div class="logo-row">
            <div class="logo-row__logo">
              <ClientAppLogo {clientAppName} />
              {#if clientAppVersion}
                <div class="logo-row__version">ver: {clientAppVersion}</div>
              {/if}
              <input type="hidden" name="clientAppVersion" value={clientAppVersion} disabled="disabled" />
            </div>
            <div class="logo-row__content">
              <ContentRow>
                <Select
                  inputId="client-app-field"
                  class="gigxr-input"
                  bind:value={session.clientAppId}
                  variant="filled"
                  label="App"
                  enhanced
                  disabled={Boolean(fromPlanId)}
                >
                  <Option value="" />
                  {#each licensedClientApps as app (app.clientAppId)}
                    {#if app.clientAppName != 'HoloScenarios' || fromPlanId}
                      <Option value={app.clientAppId} selected={app.clientAppId === session.clientAppId}>
                        {app.clientAppName}
                      </Option>
                    {/if}
                  {/each}
                  {#if licensedClientApps.length == 0}
                    <Option disabled on:click={(event) => event.stopPropagation()}>No Licensed Apps</Option>
                  {/if}
                </Select>
              </ContentRow>

              {#if fromPlanId}
                <ContentRow>
                  <Textfield
                    class="gigxr-input"
                    bind:value={sessionPlanName}
                    variant="filled"
                    disabled
                    label="Session Plan"
                  />
                </ContentRow>
              {/if}

              <ContentRow>
                <Textfield
                  input$id="session-name-field"
                  class="gigxr-input"
                  bind:value={session.sessionName}
                  variant="filled"
                  label="Session Name"
                />
              </ContentRow>

              <ContentRow>
                <Select
                  inputId="class-field"
                  class="gigxr-input"
                  bind:value={session.classId}
                  variant="filled"
                  label="Class"
                  enhanced
                >
                  <Option value="" />
                  {#each Object.values(classesById) as clazz (clazz.classId)}
                    <Option value={clazz.classId}>{clazz.className}</Option>
                  {/each}
                </Select>
              </ContentRow>
            </div>
          </div>

          <div style="display: flex">
            <ContentRow class="session-lesson-row">
              <div class="session-lesson-row__label">Lesson Start Date:</div>
              <GigXrDatepicker id="lesson-start-date-field" bind:dateValue />
            </ContentRow>

            <ContentRow class="session-lesson-row">
              <div class="session-lesson-row__label">Time:</div>
              <GigXrTimepicker bind:hourValue bind:minuteValue />
            </ContentRow>
          </div>

          <ContentRow>
            <FilledTextArea
              id="session-description-field"
              bind:value={session.description}
              label="Description"
              ariaLabel="session-description"
            />
          </ContentRow>
        </div>

        <div slot="right">
          <SessionPermissionSelect bind:session />

          <DateAccountComponent label="Created" utcDateString={session.createdOn} account={session.createdBy} />

          <DateAccountComponent label="Last Saved" utcDateString={null} account={null} show_account={false} />

          <ContentRowCenteredOnMobile>
            <Button id="create-session-save-button" class="gigxr-button" variant="unelevated" on:click={() => save()}>
              <Label>Save Changes</Label>
            </Button>
          </ContentRowCenteredOnMobile>
          {#if !fromPlanId}
            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                id="create-session-discard-changes-link"
                class="gigxr-link"
                on:click={() => discardChangesDialog.open()}
              >Discard Changes</a>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                id="create-session-save-add-session-plans-link"
                class="gigxr-link"
                on:click={saveAndAddToSessionPlans}
              >Save and Add to Session Plans</a>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a id="create-session-save-as-duplicate-link" class="gigxr-link gigxr-link--disabled">Save as Duplicate</a>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a id="create-session-archive-link" class="gigxr-link gigxr-link--disabled">Archive</a>
            </ContentRowCenteredOnMobile>
          {/if}
        </div>
      </TwoColumnSection>
    {:else}
      <LoadingView />
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<!-- <DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={() => discardChanges()} /> -->
<style>
  :global(.session-date-button) {
    width: 100%;
    background: var(--gigxr-theme-primary-1c) !important;
  }

  .logo-row__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;
  }
  .logo-row__version {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
  }

  @media (min-width: 1100px) {
    .logo-row {
      display: flex;
      justify-content: space-between;
    }

    .logo-row__logo {
      flex: 1;
      flex-direction: column;
      justify-content: start;
      margin-right: 1em;
    }

    .logo-row__content {
      flex: 3;
    }
  }

  :global(.session-lesson-row) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  :global(.session-lesson-row) .session-lesson-row__label {
    display: inline-block;
    flex: 1;
    font-weight: 700;
    padding-right: 5px;
  }

  @media (min-width: 1100px) {
    :global(.session-lesson-row) {
      width: 60%;
    }
  }
</style>
