import { writable, readable, derived, get } from 'svelte/store';

export const account = writable(null);

/**
 * @type {Writable<boolean>}
 */
export const isAuthenticated = writable(false);

/**
 * @type {Writable<string>}
 */
export const title = writable('');

/**
 * @type {Writable<Institution?>}
 */
export const institutionMasquerade = writable(null);

/**
 * @type {Derived<bool>}
 */
export const isMasquerading = derived(
  institutionMasquerade,
  ($institutionMasquerade) => $institutionMasquerade !== null,
);

/**
 * @type {Derived<AccountRole>}
 */
export const role = writable('Invalid');

/**
 * @type {Derived<string>} institutionId Guid
 */
export const institutionIdStore = derived([institutionMasquerade, account], ([$institutionMasquerade, $account]) => {
  if ($institutionMasquerade) {
    return $institutionMasquerade.institutionId;
  }
  let realInstitutionId = '';
  if ($account) {
    realInstitutionId = $account.institutionId;
  }
  return realInstitutionId;
});

/**
 * A breadcrumb path
 * @typedef {Object} BreadcrumbPath
 * @property {string} name The name to display on the page for this path
 * @property {string} location The location to navigate represented by the name
 */

/**
 * @type {Writable<[BreadcrumbPath]>}
 */
export const breadcrumbPaths = writable([]);

/**
 * @type {Writable<string>}
 */
export const errorMessage = writable('');

export const snackbar = writable(null);

/**
 * @type {Writable<string>}
 */
export const snackbarMessage = writable('');

/**
 * @type {Readable<boolean>}
 */
export const mobileView = readable(true, function start(set) {
  setWindowSize();

  window.addEventListener('resize', setWindowSize);

  function setWindowSize() {
    set(window.innerWidth < 1100);
  }

  return function stop() {
    window.removeEventListener('resize', setWindowSize);
  };
});

/**
 * @type {Writable<string>}
 */
export const apiVersion = writable('');

/**
 * @type {Writable<[string]>}
 */
export const urlHistory = writable([window.location.pathname]);
