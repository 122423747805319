<script>
  import { createEventDispatcher } from 'svelte';
  import Button, { Label, Icon } from '@smui/button';

  export let disabled = false;
  export let loading = false;
  export let displayOnly = false;
  export let buttonLabel = 'Remote';

  let className = '';
  export { className as class };

  const dispatch = createEventDispatcher();

  function clickHandler(event) {
    event.preventDefault();
    dispatch('click', event);
  }
</script>

<Button
  id="session-lobby-remote-button"
  class="gigxr-button session-lobby-remote-button {className}
    {displayOnly ? 'session-lobby-remote-button--display-only' : ''}"
  variant="unelevated"
  on:click={clickHandler}
  disabled={disabled || loading}
>
  <Label>{buttonLabel}</Label>
</Button>

<style>
  :global(.gigxr-button.session-lobby-remote-button) {
    height: 50px;
  }

  :global(.material-icons.session-lobby-remote-button__icon) {
    font-size: 30px;
    width: initial;
    height: initial;
  }

  :global(.session-lobby-remote-button--display-only) {
    background: none !important;
    opacity: 1 !important;
  }

  :global(.session-lobby-remote-button--display-only .material-icons),
  :global(.session-lobby-remote-button--display-only .mdc-button__label) {
    color: #000;
  }
</style>
