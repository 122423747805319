<script>
  import { role } from '../stores/core-store.js';
  import AccountWelcome from './AccountWelcome.svelte';
  import { link } from 'svelte-routing';
  import IconButton, { Icon } from '@smui/icon-button';
  import { slide } from 'svelte/transition';
  import List, { Item, Text, Graphic } from '@smui/list';
  import { navigate } from 'svelte-routing';
  import TopAppBar, { Row, Section, Title } from '@smui/top-app-bar';
  import { authorizedRoles as userRoles } from '../routes/users/List.svelte';
  import { authorizedRoles as classRoles } from '../routes/classes/List.svelte';
  import { authorizedRoles as departmentRoles } from '../routes/departments/List.svelte';
  import { authorizedRoles as licenseRoles } from '../routes/licenses/List.svelte';
  import { AccountRole, AccountRoleText } from '../util/api/accounts';
  import { openLogoutDialog } from '../components/my-account/LogoutDialog.svelte';
  import { realInstitutionStore } from '../stores/institutions-store.js';

  let visible = false;
  let menuButton;

  function toggleMenu() {
    visible = !visible;
  }

  function menuAction(to) {
    navigate(to);
    toggleMenu();
  }

  let scrollY = 0;
  $: if (document) {
    if (visible) {
      scrollY = window.scrollY;
      document.body.classList.add('no-scroll');
      document.body.style.top = `-${scrollY}px`;
    } else {
      document.body.classList.remove('no-scroll');
      window.scroll(0, scrollY);
    }
  }

  function checkForClickingOffMenu(event) {
    const menuClick = event.composedPath().some((element) => {
      if (element.classList && element.classList.contains('mobile-nav-menu-button')) {
        return true;
      }
      return false;
    });

    if (!menuClick) {
      visible = false;
    }
  }
</script>

<div class="header" on:click={checkForClickingOffMenu}>
  <TopAppBar variant="fixed" color="secondary">
    <Row>
      <Section>
        <div class="nav-wrapper">
          <IconButton
            id="mobile-nav-button"
            bind:this={menuButton}
            class="material-icons mobile-nav-menu-button"
            on:click={toggleMenu}
          >
            menu
          </IconButton>
        </div>
        <Title class="header__title">
          <a id="logo-home-link" href="/" use:link>
            <img src="/assets/gigxr-logo-black.svg" alt="GIGXR logo" width="92" height="34" class="main-logo" />
          </a>
        </Title>
        <div class="header__institution-name">
          {#if $realInstitutionStore && $role !== AccountRole.GIGXR_ADMIN}
            for
            {$realInstitutionStore.institutionName}
          {/if}
        </div>
      </Section>
      <Section align="end">
        <AccountWelcome />
      </Section>
    </Row>
  </TopAppBar>
</div>

{#if visible}
  <nav role="navigation" class="mobile-nav" transition:slide={{ x: -100, duration: 250 }}>
    <List>
      {#if $role === AccountRole.GIGXR_ADMIN}
        <Item id="mobile-nav-system-dashboard-link" on:SMUI:action={() => menuAction('/')} title="System Dashboard">
          <Graphic class="material-icons">view_module</Graphic>
          <Text>System Dashboard</Text>
        </Item>
      {:else}
        <Item id="mobile-nav-dashboard-link" on:SMUI:action={() => menuAction('/')} title="Dashboard">
          <Graphic class="material-icons">view_module</Graphic>
          <Text>Dashboard</Text>
        </Item>

        <Item id="mobile-nav-sessions-link" on:SMUI:action={() => menuAction('/sessions/list')} title="Saved Sessions">
          <Graphic class="material-icons">save</Graphic>
          <Text>Saved Sessions</Text>
        </Item>

        <Item
          id="mobile-nav-session-plans-link"
          on:SMUI:action={() => menuAction('/sessions/plans/list')}
          title="Session Plans"
        >
          <Graphic class="material-icons">content_paste</Graphic>
          <Text>Session Plans</Text>
        </Item>

        {#if userRoles.includes($role)}
          <Item id="mobile-nav-users-link" on:SMUI:action={() => menuAction('/users/list')} title="User Management">
            <Graphic class="material-icons">people</Graphic>
            <Text>User Management</Text>
          </Item>
        {/if}

        {#if classRoles.includes($role)}
          <Item id="mobile-nav-classes-link" on:SMUI:action={() => menuAction('/classes/list')} title="Classes">
            <Graphic class="material-icons">calendar_today</Graphic>
            <Text>Classes</Text>
          </Item>
        {/if}

        {#if departmentRoles.includes($role)}
          <Item
            id="mobile-nav-departments-link"
            on:SMUI:action={() => menuAction('/departments/list')}
            title="Departments"
          >
            <Graphic class="material-icons">account_tree</Graphic>
            <Text>Departments</Text>
          </Item>
        {/if}

        {#if licenseRoles.includes($role)}
          <Item id="mobile-nav-licenses-link" on:SMUI:action={() => menuAction('/licenses/list')} title="Licenses">
            <Graphic class="material-icons">vpn_key</Graphic>
            <Text>Licenses</Text>
          </Item>
        {/if}

        <Item
          id="mobile-nav-calibrate-room-link"
          on:SMUI:action={() => menuAction('/qr/calibrate-room')}
          title="Anchor (Calibration)"
        >
          <Graphic class="material-icons">gps_fixed</Graphic>
          <Text>Anchor (Calibration)</Text>
        </Item>
      {/if}

      <Item id="mobile-nav-my-account-link" on:SMUI:action={() => menuAction('/my-account/view')} title="My Account">
        <Graphic class="material-icons">person</Graphic>
        <Text>My Account</Text>
      </Item>

      {#if $role !== AccountRole.GIGXR_ADMIN}
        <Item id="mobile-nav-support-link" on:SMUI:action={() => menuAction('/support')} title="Support">
          <Graphic class="material-icons">help_outline</Graphic>
          <Text>Support</Text>
        </Item>
      {/if}

      <Item
    id="mobile-nav-resources-link" on:SMUI:action={() => menuAction('/resources')}
    title="Resources"
  >
      <Graphic class="material-icons">folder_copy</Graphic>
      <Text>Resources</Text>
    </Item>

      <Item id="mobile-nav-logout-link" on:SMUI:action={() => openLogoutDialog()} title="Logout">
        <Graphic class="material-icons">power_settings_new</Graphic>
        <Text>Logout</Text>
      </Item>
    </List>
  </nav>
{/if}

{#if visible}
  <div id="mobile-nav-clickable-background-element" class="mobile-nav-clickable-background" on:click={toggleMenu} />
{/if}

<style>
  :global(.mdc-top-app-bar) {
    border-radius: 0 0 12px 12px;
    z-index: 10;
    top: 0;
  }

  :global(.mobile-nav span) {
    color: #fff;
  }

  :global(.mdc-top-app-bar__row) {
    z-index: 6;
  }

  :global(.mdc-top-app-bar__title) {
    padding-left: 0;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    background: #30373c;
    border: 1px solid #ccc;
    z-index: 1;

    width: 100%;
    padding-top: 60px;
    height: 100vh;
  }

  @media (max-width: 1099px) {
    /* Disable top-app-bar from being resized mobile <=> tablet */
    :global(.mdc-top-app-bar),
    :global(.mdc-top-app-bar__row) {
      height: 56px;
    }
  }

  .mobile-nav-clickable-background {
    height: 56px;
    width: calc(100vw - 84px);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    opacity: 0;
  }

  @media (min-width: 1100px) {
    :global(.mdc-top-app-bar) {
      background: inherit;
      display: inline;
      position: relative;
    }

    :global(.mdc-top-app-bar--fixed-scrolled) {
      box-shadow: none;
    }
  }

  .main-logo {
    margin-left: 8px;
  }

  .header {
    margin: 0 auto;
    position: relative;
  }

  :global(.header__title) {
    display: inline-block;
    min-width: 113px;
  }

  .header__institution-name {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :global(.mobile-nav .mdc-list-item__graphic) {
    pointer-events: none;
  }

  .mobile-nav {
    overflow-y: scroll;
    height: 100%;
    /** Edge */
    -ms-overflow-style: none;
  }

  /** Chrome, Safari, and Opera */
  .mobile-nav::-webkit-scrollbar {
    display: none;
  }

  :global(body.no-scroll) {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }

  @media (max-width: 449px) {
    .header__institution-name {
      display: none;
    }
  }

  @media (min-width: 1100px) {
    .header {
      padding-left: 105px;
      padding-right: 25px;
      max-width: 1100px;
      padding-top: 28px;
    }

    .main-logo {
      margin-left: 0;
    }

    :global(.nav-wrapper button) {
      display: none;
    }

    :global(.mdc-top-app-bar__section) {
      padding-left: 0;
      padding-right: 0;
    }

    .mobile-nav {
      display: none;
    }

    .mobile-nav-clickable-background {
      display: none;
    }
  }
</style>
