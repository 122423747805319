import { element } from 'svelte/internal';
import { FilterByCheckboxSet, FilterByRadioSet, FilterBySelectSet, SortBySet } from '../filters';

function SessionPlanSortBySet(defaultFilterValue = 'Date Created (Recent)') {
  return new SortBySet({
    description: 'Sort by',
    defaultValue: defaultFilterValue,
    sortOptions: new Map([
      [
        'Date Created (Recent)',
        (a, b) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
      [
        'Date Created (Oldest)',
        (b, a) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
      [
        'Date Saved (Recent)',
        (a, b) => {
          if (a.modifiedOn < b.modifiedOn) return 1;
          if (a.modifiedOn > b.modifiedOn) return -1;
          return 0;
        },
      ],
      [
        'Date Saved (Oldest)',
        (b, a) => {
          if (a.modifiedOn < b.modifiedOn) return 1;
          if (a.modifiedOn > b.modifiedOn) return -1;
          return 0;
        },
      ],

      [
        'Name (A-Z)',
        (a, b) => {
          const sessionNameA = a.sessionName.toLowerCase();
          const sessionNameB = b.sessionName.toLowerCase();
          if (sessionNameA < sessionNameB) return -1;
          if (sessionNameA > sessionNameB) return 1;
          return 0;
        },
      ],
      [
        'Name (Z-A)',
        (b, a) => {
          const sessionNameA = a.sessionName.toLowerCase();
          const sessionNameB = b.sessionName.toLowerCase();
          if (sessionNameA < sessionNameB) return -1;
          if (sessionNameA > sessionNameB) return 1;
          return 0;
        },
      ],
    ]),
  });
}

function SessionPlanFilterByClassSet(classes, defaultFilterValue = '*') {
  const filterByClasses = new FilterBySelectSet({
    description: 'Filter by Class',
    label: 'Class',
    defaultValue: defaultFilterValue,
    filterOptions: {
      '*': 'All Classes',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.classId === selectedValue);
    },
  });

  classes.forEach((clazz) => (filterByClasses.filterOptions[clazz.classId] = clazz.className));

  return filterByClasses;
}

function SessionPlanFilterByClientAppSet(clientApps, defaultFilterValue = 'All') {
  const filterByAppSet = new FilterByRadioSet({
    description: 'Filter by App',
    defaultValue: defaultFilterValue,
    filterOptions: {
      All: (element) => true,
    },
  });

  clientApps.forEach(
    (app) =>
      (filterByAppSet.filterOptions[app.clientAppName] = (element) => {
        return element.clientAppId === app.clientAppId;
      }),
  );

  return filterByAppSet;
}

function SessionPlanFilterIncludeArchivedSet(defaultFilterValue = false) {
  return new FilterByCheckboxSet({
    description: 'Archived Session Plans',
    defaultValue: defaultFilterValue,
    filterOptions: {
      option: 'Include Archived',
    },
    filterCallback: (array, includeArchived) => {
      if (includeArchived) return array;
      return array.filter((element) => element.sessionStatus !== 'Archived');
    },
  });
}

function SessionPlanFilterIncludeSharedSessionPlansSet(isGigXrAdmin) {
  return new FilterByCheckboxSet({
    description: 'GigXR Shared Session Plans',
    defaultValue: isGigXrAdmin,
    filterOptions: {
      option: 'Include GigXR Shared',
    },
    filterCallback: (array, includeSharedSessionPlans) => {
      if (includeSharedSessionPlans) return array;
      return array.filter((element) => !element.gigXrBranded);
    },
  });
}

/**
 *
 * @param {Mixed} versions can be an array of object or null
 * @returns {Array} can be an Array or null
 */
function SessionPlanFilterIncludeVersionsSet(versions, defaultFilterValue = `*`) {
  const filterByVersions = new FilterBySelectSet({
    description: 'Filter by Version',
    label: 'Version',
    defaultValue: defaultFilterValue,
    filterOptions: {
      '*': 'All Versions',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.clientAppVersion === selectedValue);
    },
  });

  // NOTE: hard-coding "HoloScenarios" into the UI for now.
  // TODO: Please remove when we have a better way of doing this
  versions.forEach((vers) => (filterByVersions.filterOptions[vers] = 'HoloScenarios ' + vers));

  return filterByVersions;
}

/**
 *
 * @param {Mixed} versions can be an array of object or null
 * @returns {Array} can be an Array or null
 */
function SessionPlanFilterIncludeVersionsLatestDefaultSet(versions, defaultFilterValue = 'latest') {
  const filterByVersions = new FilterBySelectSet({
    description: 'Filter by Version',
    label: 'Version',
    defaultValue: defaultFilterValue,
    filterOptions: {
      '*': 'All Versions',
      latest: 'Latest',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      if (selectedValue === 'latest') return array.filter((element) => element.clientAppVersion == versions[0]);
      return array.filter((element) => element.clientAppVersion === selectedValue);
    },
  });

  // NOTE: hard-coding "HoloScenarios" into the UI for now.
  // TODO: Please remove when we have a better way of doing this
  versions.forEach((vers) => (filterByVersions.filterOptions[vers] = 'HoloScenarios ' + vers));

  return filterByVersions;
}

export {
  SessionPlanSortBySet,
  SessionPlanFilterByClassSet,
  SessionPlanFilterByClientAppSet,
  SessionPlanFilterIncludeVersionsSet,
  SessionPlanFilterIncludeArchivedSet,
  SessionPlanFilterIncludeSharedSessionPlansSet,
  SessionPlanFilterIncludeVersionsLatestDefaultSet,
};
