<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.INSTRUCTOR, AccountRole.DEPARTMENT_ADMIN, AccountRole.INSTITUTION_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import {
    role,
    account,
    title,
    errorMessage,
    breadcrumbPaths,
    snackbarMessage,
    snackbar,
  } from '../../../stores/core-store';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';
  import { authenticatedGet, authenticatedPost, authenticatedPut } from '../../../util/api';
  import { getAccountId } from '../../../util/account';
  import { navigate } from 'svelte-routing';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import DateAccountComponent from '../../../components/DateAccountComponent.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import FilledTextArea from '../../../components/FilledTextArea.svelte';
  import ContentRowCenteredOnMobile from '../../../components/ContentRowCenteredOnMobile.svelte';
  import PageControlsRow from '../../../components/PageControlsRow.svelte';
  import LoadingView from '../../../components/LoadingView.svelte';
  import { fetchDepartments } from '../../../util/api/departments';
  import { editClass, fetchClass, canEditClass } from '../../../util/api/classes';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import { RedirectError } from '../../../util/errors';
  import DiscardChangesDialog from '../../../components/DiscardChangesDialog.svelte';

  export let classId;

  setAuthorizedRoles(authorizedRoles);

  title.set('Edit Class');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Class Management',
      location: '/classes/list',
    },
    {
      name: 'Edit Class',
      location: `/classes/edit/${classId}`,
    },
  ]);

  let departmentsById = {};

  let lastSavedClass = {
    className: '',
    description: '',
    classStatus: 'Active',
    departmentId: '',
  };

  let loading = true;
  let clazz = { ...lastSavedClass };
  let discardChangesDialog;

  onMount(async () => {
    let departments;
    [departments, lastSavedClass] = await Promise.all([fetchDepartments(), fetchClass(classId)]);
    departments.forEach((d) => (departmentsById[d.departmentId] = d));

    clazz = { ...lastSavedClass };

    if (!canEditClass(lastSavedClass)) {
      throw new RedirectError('Insufficient access to edit this class.', `/classes/view/${classId}`);
    }

    loading = false;
  });

  async function editClassHandler(event) {
    event.preventDefault();
    const newClass = await editClass(clazz);
    snackbarMessage.set('Class saved!');
    $snackbar.open();
    navigate(`/classes/view/${newClass.classId}`);
  }
</script>

{#if !loading}
  <PrimaryContent>
    <PageControlsRow>
      <div slot="left">
        <h2>{clazz.className ? clazz.className : 'Edit a Class'}</h2>
      </div>
      <div slot="right" />
    </PageControlsRow>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      <TwoColumnSection>
        <div slot="left">
          <ContentRow>
            <Textfield
              input$id="class-name-field"
              class="gigxr-input"
              bind:value={clazz.className}
              variant="filled"
              label="Class Name"
            />
          </ContentRow>

          <ContentRow>
            <Select
              inputId="department-field"
              class="gigxr-input"
              bind:value={clazz.departmentId}
              variant="filled"
              label="Department"
              enhanced
              disabled
            >
              {#each Object.values(departmentsById) as department (department.departmentId)}
                <Option value={department.departmentId}>{department.departmentName}</Option>
              {/each}
            </Select>
          </ContentRow>

          <ContentRow>
            <FilledTextArea
              id="class-description-field"
              bind:value={clazz.description}
              label="Description"
              ariaLabel="class-description"
            />
          </ContentRow>
        </div>
        <div slot="right">
          <ContentRow>
            <Select
              inputId="class-status-field"
              class="gigxr-input"
              bind:value={clazz.classStatus}
              variant="filled"
              label="Status"
              enhanced
            >
              <Option value="Inactive">Inactive</Option>
              <Option value="Active">Active</Option>
            </Select>
          </ContentRow>

          <DateAccountComponent label="Added" utcDateString={clazz.createdOn} account={clazz.createdBy} />

          <ContentRowCenteredOnMobile>
            <Button id="edit-class-button" class="gigxr-button" variant="unelevated" on:click={editClassHandler}>
              <Label>Save Changes</Label>
            </Button>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              id="edit-class-discard-changes-link"
              class="gigxr-link"
              on:click={() => discardChangesDialog.open()}
            >Discard Changes</a>
          </ContentRowCenteredOnMobile>
        </div>
      </TwoColumnSection>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={() => (clazz = { ...lastSavedClass })} />

<style>
</style>
