import { FilterByCheckboxSet, FilterByRadioSet, FilterBySelectSet, SortBySet } from '../filters';
import { isDateInMostRecentDays, isDateToday, utcStringToLocalDate } from '../dates';

function SessionSortBySet(defaultFilterValue = 'Lesson Date (Latest)') {
  return new SortBySet({
    description: 'Sort by',
    defaultValue: defaultFilterValue,
    sortOptions: new Map([
      [
        'Lesson Date (Latest)',
        (a, b) => {
          if (a.lessonDate < b.lessonDate) return 1;
          if (a.lessonDate > b.lessonDate) return -1;
          return 0;
        },
      ],
      [
        'Lesson Date (Earliest)',
        (b, a) => {
          if (a.lessonDate < b.lessonDate) return 1;
          if (a.lessonDate > b.lessonDate) return -1;
          return 0;
        },
      ],

      [
        'Date Saved (Recent)',
        (a, b) => {
          if (a.modifiedOn < b.modifiedOn) return 1;
          if (a.modifiedOn > b.modifiedOn) return -1;
          return 0;
        },
      ],
      [
        'Date Saved (Oldest)',
        (b, a) => {
          if (a.modifiedOn < b.modifiedOn) return 1;
          if (a.modifiedOn > b.modifiedOn) return -1;
          return 0;
        },
      ],

      [
        'Session Name (A-Z)',
        (a, b) => {
          const sessionNameA = a.sessionName.toLowerCase();
          const sessionNameB = b.sessionName.toLowerCase();
          if (sessionNameA < sessionNameB) return -1;
          if (sessionNameA > sessionNameB) return 1;
          return 0;
        },
      ],
      [
        'Session Name (Z-A)',
        (b, a) => {
          const sessionNameA = a.sessionName.toLowerCase();
          const sessionNameB = b.sessionName.toLowerCase();
          if (sessionNameA < sessionNameB) return -1;
          if (sessionNameA > sessionNameB) return 1;
          return 0;
        },
      ],
    ]),
  });
}

function SessionFilterByLessonDateSet(defaultFilterValue = 'All') {
  return new FilterByRadioSet({
    description: 'Filter by Lesson Date',
    defaultValue: defaultFilterValue,
    filterOptions: {
      All: (element) => true,
      Today: (element) => {
        const localDate = utcStringToLocalDate(element.lessonDate);
        return isDateToday(localDate);
      },
      'Last 30 Days': (element) => {
        const localDate = utcStringToLocalDate(element.lessonDate);
        return isDateInMostRecentDays(localDate, -30);
      },
      'Last 7 Days': (element) => {
        const localDate = utcStringToLocalDate(element.lessonDate);
        return isDateInMostRecentDays(localDate, -7);
      },
      'Next 7 Days': (element) => {
        const localDate = utcStringToLocalDate(element.lessonDate);
        return isDateInMostRecentDays(localDate, 7);
      },
      'Next 30 Days': (element) => {
        const localDate = utcStringToLocalDate(element.lessonDate);
        return isDateInMostRecentDays(localDate, 30);
      },
    },
  });
}

function SessionFilterByClassSet(classes, defaultFilterValue = '*') {
  const filterByClasses = new FilterBySelectSet({
    description: 'Filter by Class',
    label: 'Class',
    defaultValue: defaultFilterValue,
    filterOptions: {
      '*': 'All Classes',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.classId === selectedValue);
    },
  });

  classes.forEach((clazz) => (filterByClasses.filterOptions[clazz.classId] = clazz.className));

  return filterByClasses;
}

function SessionFilterByClientAppSet(clientApps, defaultFilterValue = 'All') {
  const filterByClientAppSet = new FilterByRadioSet({
    description: 'Filter by App',
    defaultValue: defaultFilterValue,
    filterOptions: {
      All: (element) => true,
    },
  });

  clientApps.forEach(
    (app) =>
      (filterByClientAppSet.filterOptions[app.clientAppName] = (element) => {
        return element.clientAppId === app.clientAppId;
      }),
  );

  return filterByClientAppSet;
}

function SessionFilterIncludeArchivedSet(defaultFilterValue = false) {
  return new FilterByCheckboxSet({
    description: 'Archived Sessions',
    defaultValue: defaultFilterValue,
    filterOptions: {
      option: 'Include Archived',
    },
    filterCallback: (array, includeArchived) => {
      if (includeArchived) return array;
      return array.filter((element) => element.sessionStatus !== 'Archived');
    },
  });
}

/**
 *
 * @param {Mixed} versions can be an array of object or null
 * @returns {Array} can be an Array or null
 */
function SessionFilterIncludeVersionsSet(versions, defaultFilterValue = '*') {
  const filterByVersions = new FilterBySelectSet({
    description: 'Filter by Version',
    label: 'Version',
    defaultValue: defaultFilterValue,
    filterOptions: {
      '*': 'All Versions',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.clientAppVersion === selectedValue);
    },
  });

  // NOTE: hard-coding "HoloScenarios" into the UI for now.
  // TODO: Please remove when we have a better way of doing this
  versions.forEach((vers) => (filterByVersions.filterOptions[vers] = 'HoloScenarios ' + vers));

  return filterByVersions;
}

export {
  SessionSortBySet,
  SessionFilterByLessonDateSet,
  SessionFilterByClassSet,
  SessionFilterByClientAppSet,
  SessionFilterIncludeVersionsSet,
  SessionFilterIncludeArchivedSet,
};
