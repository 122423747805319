<script>
  import { title, breadcrumbPaths } from '../stores/core-store';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';

  title.set('Support');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Support',
      location: '/support',
    },
  ]);
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <div class="support-row">
      <div class="support-row__left"><span class="support-row__header">Answers to frequently asked questions (FAQs)</span></div>
      <div class="support-row__right"><a href="https://help.gigxr.com/knowledge" target="_blank" rel="noopener noreferrer">GigXR Help Center</a></div>
    </div>

    <span>Please contact your institution admin for support.</span>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .support-row {
    margin-bottom: 2em;
  }

  .support-row__header {
    font-weight: 700;
  }

  .support-row__right {
    margin-top: 1em;
  }
  .support-row a { font-weight: bold;}

  @media (min-width: 1100px) {
    .support-row {
      display: flex;
    }

    .support-row__left {
      flex: 1;
    }

    .support-row__right {
      flex: 1;
      margin-top: 0;
    }
  }
</style>
