<script>
  export let clientAppName = null;
  export let variant = '';
</script>

<div
  class="client-app-logo {variant === 'small' ? 'client-app-logo--small' : variant === 'medium' ? 'client-app-logo--medium' : ''}"
>
  <div class="client-app-logo__outer-circle">
    {#if clientAppName === 'HoloPatient'}
      <img class="client-app-logo__icon" src="/icons/holopatient.svg" alt="HoloPatient Icon" />
    {:else if clientAppName === 'HoloHuman'}
      <img class="client-app-logo__icon" src="/icons/holohuman.svg" alt="HoloHuman Icon" />
    {:else if clientAppName === 'HoloHuman+'}
      <img class="client-app-logo__icon" src="/icons/holohuman+.svg" alt="HoloHuman+ Icon" />
    {:else if clientAppName === 'HoloChem' || clientAppName === 'HoloChemLab'}
      <img class="client-app-logo__icon" src="/icons/holochemlab.svg" alt="HoloChem Icon" />
    {:else if clientAppName === 'HoloScenarios'}
      <img class="client-app-logo__icon" src="/icons/holoscenarios.svg" alt="HoloScenarios Icon" />
    {:else}<img class="client-app-logo__icon" src="/icons/gigxr-logo-white.svg" alt="GIGXR Icon" />{/if}
  </div>
</div>

<style>
  .client-app-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .client-app-logo__outer-circle {
    display: inline-block;
    border: 2px solid #cad5dd;
    padding: 7px;
    border-radius: 50%;
  }

  .client-app-logo__icon {
    width: 125px;
    height: 125px;
    background: #cad5dd;
    border-radius: 50%;
  }

  /* variant: small */

  .client-app-logo--small {
    width: initial;
  }

  .client-app-logo--small .client-app-logo__outer-circle {
    border-color: var(--gigxr-theme-secondary-3c);
    padding: 2px;
    border-width: 1px;
  }

  .client-app-logo--small .client-app-logo__icon {
    background: var(--gigxr-theme-secondary-3c);
    width: 36px;
    height: 36px;
  }

  /* variant: medium */

  .client-app-logo--medium {
    width: initial;
  }

  .client-app-logo--medium .client-app-logo__outer-circle {
    border-color: var(--gigxr-theme-secondary-3c);
    padding: 3px;
    border-width: 1px;
  }

  .client-app-logo--medium .client-app-logo__icon {
    background: var(--gigxr-theme-secondary-3c);
    width: 50px;
    height: 50px;
  }
</style>
