<script>
  export let stage = 1;
  export let stageLabels = ['Select App', 'Select Session Plan', 'Customize Session'];
</script>

<div class="gigxr-create-breadcrum-container {stage == 2 ? 'select-plan' : ''} {stage == 3 ? 'create-session' : ''}">
  <ul>

    {#each stageLabels as stageLabel, i}
    <li>
      <span
        onclick={stage-2 == i ? "history.back()" : ""}
        id="gigxr-progress{i+1}"
        class="material-icons material-icons-round {stage >= (i+1) ? 'active' : ''}"
      >fiber_manual_record</span>
      <p
      onclick={stage-2 == i ? "history.back()" : ""}
      >{stageLabel}</p>
    </li>
    {/each}
  </ul>
</div>

<style>
  ul {
    list-style: none;
    padding: 0;
  }
  ul li {
    padding: 0;
    margin: 10px;
  }
  .material-icons-round {
    color: #e1e1f3;
  }
  @media only screen and (min-width: 600px) {
    .gigxr-create-breadcrum-container {
      text-align: center;
      margin: auto;
      width: 60%;
    }
    .gigxr-create-breadcrum-container ul {
      display: flex;
      margin: auto;
      text-align: center;
      justify-content: center;
    }
    .gigxr-create-breadcrum-container ul li {
      position: relative;
      padding: 20px;
    }
    #gigxr-progress1::after {
      content: '';
      background: #e1e1f3;
      height: 3px;
      width: 150px;
      display: block;
      position: absolute;
      left: 66px;
      top: 30px;
    }
    #gigxr-progress2::after {
      content: '';
      background: #e1e1f3;
      height: 3px;
      width: 110%;
      display: block;
      position: absolute;
      left: 94px;
      top: 30px;
    }
    .gigxr-create-breadcrum-container .material-icons-round {
      z-index: +1;
    }
    .material-icons-round.active {
      color: #8b8bc3;
    }
    .gigxr-create-breadcrum-container :global(.inactive) {
      color: #e1e1f3;
    }
/* 
    .stage-item {
    }

    :global(.extend-line) .stage-item::after {
      background-color: #8b8bc3;
    } */
    :global(.select-plan) #gigxr-progress1::after {
      background-color: #8b8bc3;
    }
    :global(.create-session) #gigxr-progress1::after {
      background-color: #8b8bc3;
    }
    :global(.create-session) :global(.active)#gigxr-progress2::after {
      background-color: #8b8bc3;
    }
  }
</style>
